import React from 'react';

import { useTranslation } from 'react-i18next';

import Keyboard from 'react-simple-keyboard';
import './Keyboard.css';

import _ from 'lodash';

export default function GixelKeyboard(props) {

  const { t } = useTranslation();

  const keyboardRef = React.useRef();

  const [layoutName, setLayoutName] =  React.useState('default');
  const [isLock, setIsLock] =  React.useState(false);

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default")
  };

  const handleKeyPress = (button) => {
    switch(button) {
      case '{shift}':
        handleShift();
        setIsLock(false);
        break;
      case '{lock}':
        setIsLock((isLock) => !isLock);
        handleShift();
        break;
      default:
        if (!isLock) {
          setLayoutName('default');
        }
        if (props.onKeyPress) {
          props.onKeyPress(button);
        }
        break;
    }
  }

  let layout = props.layout ? props.layout : {
    default: t('keyboard_layout_default', {returnObjects: true}),
    shift: t('keyboard_layout_default_shift', {returnObjects: true}),
  }

  return (
    <React.Fragment>
          <Keyboard
            inputName={props.inputName}
            keyboardRef={r => {
              keyboardRef.current = r;
              if (props.keyboardRef) {
                if(_.isFunction(props.keyboardRef)) {
                  props.keyboardRef(r);
                } else {
                  props.keyboardRef.current = r;
                }
              }
              console.log('keyboard ref...');
              console.dir(props)
              if (props.input) { // we need to set the keyboard input, otherweise it will overwrite on first virtualkeyboard input if the input wasnt set any other way..
                console.log('keyboard setting input...');
                console.log(props.input)
                props.inputName ?
                  r.setInput(props.input, props.inputName) :
                  r.setInput(props.input)
              }
            }}
            onChange={props.onChange}
            onChangeAll={props.onChangeAll}
            onKeyPress={handleKeyPress}
            layoutName={layoutName}
            layout={layout}
            display={{
             '{bksp}': t('keyboard_bksp'),
             '{enter}': t('keyboard_enter'),
             '{shift}': t('keyboard_shift'),
             '{s}': t('keyboard_shift'),
             '{tab}': t('keyboard_tab'),
             '{lock}': t('keyboard_lock'),
             '{accept}': t('keyboard_accept'),
             '{space}': t('keyboard_space'),
             '{//}': t('keyboard_//'),
            }}
          />
          </React.Fragment>
      );
}
