import React, {useContext, useEffect} from 'react';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import Typography from '@material-ui/core/Typography';


import { Context } from '../Context';

import InspectionImage from './InspectionImage';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  inspectionImageContainer: {
    height: 'calc(90vh - ' + theme.spacing(6) + 'px)',
    paddingBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 'calc(100vw - 128px)', // calculated on the fly...
  },
}));


function History () {
  const { t } = useTranslation();
  const context = useContext(Context);

  useEffect (() => {
    context.updateHistoryView({operationType: 'set', data: context.imageHistory});
  // eslint-disable-next-line
  }, []); // NOTE: we dont use context here to avoid refrehsing.. set our context imageHistory up once.


  const classes = useStyles();


  console.log("HISOTRY");
  let styleTmp = {
    width: context.isDrawerOpen ? 'calc(100vw - 308px)' : 'calc(100vw - 128px)',
  };
  if (_.size(context.historyView) > 0) {
    return _.map(context.historyView, (image) => {
      return (<div key={image.imageId} className={classes.inspectionImageContainer} style={styleTmp}>
        <InspectionImage image={image} />
        </div>
      );
    })
  } else {
      return <div className={classes.inspectionImageContainer}>
        <Typography>{t('Images history is empty')}</Typography>
      </div>
  }
}

export default History;
