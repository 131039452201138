export default {
  "translation": {
    "Inspection": "Inspection",
    "History": "History",
    "Settings": "Settings",

    'keyboard_bksp': '⌫',
    'keyboard_enter': '↵',
    'keyboard_shift': '⇧',
    'keyboard_tab': '⇥',
    'keyboard_lock': '⇪',
    'keyboard_accept': 'Senden',
    'keyboard_space': ' ',
    'keyboard_//': ' ',
    'keyboard_layout_default': [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "q w e r t y u i o p [ ] \\",
      "{lock} a s d f g h j k l ; '",
      "{shift} z x c v b n m , . / {shift}",
      "{space}"
    ],
    'keyboard_layout_default_shift': [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "Q W E R T Y U I O P { } |",
      '{lock} A S D F G H J K L : "',
      "{shift} Z X C V B N M < > ? {shift}",
      "{space}"
    ],
    'keyboard_layout_enter': [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "q w e r t y u i o p [ ] \\",
      "{lock} a s d f g h j k l ; ' {enter}",
      "{shift} z x c v b n m , . / {shift}",
      "{space}"
    ],
    'keyboard_layout_enter_shift': [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "Q W E R T Y U I O P { } |",
      '{lock} A S D F G H J K L : " {enter}',
      "{shift} Z X C V B N M < > ? {shift}",
      "{space}"
    ],
    'keyboard_layout_ip': [
      "1 2 3 4 5",
      "6 7 8 9 0",
      ". {bksp}",
    ],
    'Preprocessing': 'Pre',
  }
}
