import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';

import useImage from './base/useImage';

import {REJECTED_COLOR} from '../Defines';


const ImageDraw = forwardRef((props, ref) => {
  const [drawingCanvas, setDrawingCanvas] = useState(null);
  const [drawingContext, setDrawingContext] = useState(null);

  const [markImage] = useImage(props.markImage ? props.markImage : "");


  const stageRef = useRef(null);
  const drawingImageRef = useRef(null);
  const layerRef = useRef(null);

  let minX = null;
  let minY = null;
  let maxX = null;
  let maxY = null;

  let isPaint = false;
  let lastPointerPosition;

  const getPos = () => {
    var pos = stageRef.current.getPointerPosition();
    pos.x = pos.x/props.size.scale.x;
    pos.y = pos.y/props.size.scale.y;
    return pos
  }

  const startDrawing = () => {
    if (props.isDrawing) {
      isPaint = true;
      lastPointerPosition = getPos();
    }
  }

  const stopDrawing = () => {
    if (props.autoSaveDrawing && isPaint) {
      props.autoSaveDrawing(getDrawingImage());
    }
    isPaint = false;
  }

  useEffect (()=> {
    console.log("SETTING UP IMAGE DRAW");
    let tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = props.crop.width;
    tmpCanvas.height = props.crop.height;
    setDrawingCanvas(tmpCanvas);

    let tmpContext = tmpCanvas.getContext("2d")
    tmpContext.globalCompositeOperation = 'source-over';
    tmpContext.strokeStyle = REJECTED_COLOR;
    tmpContext.lineJoin = 'round';
    tmpContext.lineWidth = 5 / props.size.scale.x;
    setDrawingContext(tmpContext);

    if (markImage) {
      drawingContext.drawImage(markImage, 0,0);
      if (layerRef.current) {
        layerRef.current.draw();
      }
    }
  // eslint-disable-next-line
  }, [props.taskImageId, markImage, props.crop.height, props.crop.width, props.size.scale.x]) // NOTE: not including drawingContext here...

  function getDrawingImage() {
    if (drawingCanvas) {
      return drawingCanvas.toDataURL();
    }
    return null;
  }

  useImperativeHandle(ref, () => ({
    getDrawingImage() {
      if (drawingCanvas) {
        return drawingCanvas.toDataURL();
      }
      return null;
    },
    clearDrawingImage() {
      if (drawingContext && drawingCanvas) {
        console.log('clearing drawing...');
        drawingContext.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
        if (layerRef.current) {
          layerRef.current.draw();
        }
        if (props.autoSaveDrawing) {
          props.autoSaveDrawing(getDrawingImage());
        }
      }
      minX = null;
      minY = null;
      maxX = null;
      maxY = null;
    }
  }));

  // if (!drawingCanvas) {
  //   let tmpCanvas = document.createElement("canvas");
  //   tmpCanvas.width = props.crop.width;
  //   tmpCanvas.height = props.crop.height;
  //   setDrawingCanvas(tmpCanvas);
  //   let tmpContext = tmpCanvas.getContext("2d")
  //   tmpContext.globalCompositeOperation = 'source-over';
  //   tmpContext.strokeStyle = '#df4b26';
  //   tmpContext.lineJoin = 'round';
  //   tmpContext.lineWidth = 5 / props.size.scale.x;
  //   setDrawingContext(tmpContext);
  // }

  if (markImage) {
    drawingContext.drawImage(markImage, 0,0);
    if (layerRef.current) {
      layerRef.current.draw();
    }
  }

  const touchDraw = () => { // allow continous touch draws even between saves.
    isPaint = true;
    if (!lastPointerPosition) {
      console.log("TOUCHDRAW lastPointerPosition not set..")
      lastPointerPosition = getPos();
    }
    draw();
  }
  const draw = () => {
    if (!isPaint) {
      return;
    }

    drawingContext.beginPath();

    var localPos = {
      x: lastPointerPosition.x - drawingImageRef.current.x(),
      y: lastPointerPosition.y - drawingImageRef.current.y()
    };
    drawingContext.moveTo(localPos.x, localPos.y);
    var pos = getPos();
    localPos = {
      x: pos.x - drawingImageRef.current.x(),
      y: pos.y - drawingImageRef.current.y()
    };

    drawingContext.lineTo(localPos.x, localPos.y);

    drawingContext.closePath();
    drawingContext.stroke();

    lastPointerPosition = pos;
    layerRef.current.draw();
    // update our boundaries for our bounding rect
    if (minX === null || minX > localPos.x) {
      minX = localPos.x;
    }
    if (minY === null || minY > localPos.y) {
      minY = localPos.y;
    }
    if (maxX === null || maxX < localPos.x) {
      maxX = localPos.x;
    }
    if (maxY === null || maxY < localPos.y) {
      maxY = localPos.y;
    }
  };


  return (
    <Stage
      ref={stageRef}
      width={props.crop.width * props.size.scale.x}
      height={props.crop.height * props.size.scale.y}
      scale={props.size.scale}
      onMousedown={startDrawing}
      onTouchstart={startDrawing}
      onMouseup={stopDrawing}
      onTouchend={stopDrawing}
      onMousemove={draw}
      onTouchmove={touchDraw}
    >
      <Layer>
        <KonvaImage
          image={props.image}
          width={props.crop.width}
          height={props.crop.height}
          crop={props.crop}
        />
      </Layer>
      <Layer ref={layerRef}>
        <KonvaImage
          image={drawingCanvas}
          ref={drawingImageRef}
          width={props.crop.width}
          height={props.crop.height}
          onMouseenter={()=>{stageRef.current.container().style.cursor = props.isDrawing ? 'crosshair' : 'default';}}
          onMouseleave={()=>{stageRef.current.container().style.cursor = 'default';}}
        />
      </Layer>
    </Stage>
  )
});

export default ImageDraw;
