import React, {useContext, useEffect} from 'react';


import { Context } from '../Context';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';


function ImageDisplay(props) {
  const context = useContext(Context);
  const [imageData, setImageData] = React.useState("iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="); // empty pixel
  const [imageType, setImageType] = React.useState("gif");

  useEffect (() => {
    const listener = (payload) => {
      console.log('onliveview');
      // if (props.enabled.current) { // avoid writing to sate when we're cleanedup..
        setImageData(payload.imageData);
        setImageType(payload.imageType);
      // }
    }
    context.socket.on('liveview', listener);
    return () => {
      console.log('removing image listener - useEffect CLEANUP');
      context.socket.off('liveview', listener);
    }
  }, [context.socket]);
  return <img alt='liveview' src={`data:image/${imageType};base64,${imageData}`} style={{height: '100%', width: '100%', objectFit: 'contain'}}/>;

}


function Liveview(props) {
  const context = useContext(Context);
  const { t } = useTranslation();

  // const enabled = React.useRef(false); // enabled is used to avoid the image component to update while we're shutting down on the effect..

  useEffect (() => {
    // enabled.current = true;
    console.log('LIVEVIEW ENABELING - useEffect');
    context.socket.emit('setLiveView', {status: 'enabled'});
    const intervalId = setInterval(() => {
      context.socket.emit('setLiveView', {status: 'enabled'});
    }, 3000);
    return  () => {
      clearInterval(intervalId);
      // enabled.current = false;
      console.log('LIVEVIEW DISABELING - useEffect CLEANUP');
      context.socket.emit('setLiveView', {status: 'disabled'});
    };
  }, [context.socket]);

  // return <ImageDisplay enabled={enabled} />;
  return <div style={{width: '100%', height: '100%'}}>
            <ImageDisplay />
            <Typography variant='caption' color='primary'>{t('All image triggers are disabled while liveview is active!')}</Typography>
          </div>;

}

export default Liveview;
