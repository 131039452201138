
import React, {useContext, useEffect} from 'react';

import _ from 'lodash';
import { Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { useCollection, useDocument } from 'react-firebase-hooks/firestore';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Divider from '@material-ui/core/Divider';


import IconButton from '@material-ui/core/IconButton';

import Chip from '@material-ui/core/Chip';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import CustomerIcon from '@material-ui/icons/BusinessCenter';
import ProductsIcon from '@material-ui/icons/Apps';
import InspectionIcon from '@material-ui/icons/ImageSearch';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import OfflineIcon from '@material-ui/icons/SignalWifiOff';

import AiEdgeIcon from '@material-ui/icons/Done';
import AiCloudIcon from '@material-ui/icons/Cloud';

import AIStatusOffIcon from '@material-ui/icons/CloudOff';

import ManualTriggerIcon from '@material-ui/icons/AddToPhotos';
// import ManualTriggerModeIcon from '@material-ui/icons/TouchApp';
import browserhistory from '../Browserhistory';


import { Offline } from "react-detect-offline";

import { Context } from '../Context';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    overflow: 'hidden',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  bottomlist: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 0,
    width: '100%',
  },
  manualTrigger: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  manualTriggerIcon: {
    color: 'black',
  },
  productSelect: {
    maxWidth: '100%',
  },
}));

function CustomDrawer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const context = useContext(Context);

  const [productMenuOpen, setProductMenuOpen] = React.useState(false);
  const [customerMenuOpen, setCustomerMenuOpen] = React.useState(false);

  const [customerList, setCustomerList] = React.useState({});


  const [productsValue, productsLoading, productsError] = useCollection(
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('products').orderBy('name').orderBy('_createdAt', 'asc')
  );
  if (productsError) console.error(productsError);


  const [aistatusValue, aistatusLoading, aistatusError] = useDocument(
    firebase.firestore().doc('publicsettings/aistatus')
  );
  if (aistatusError) console.error(aistatusError);

  let isAIOn = false;

  if (!aistatusLoading && aistatusValue) {
    isAIOn = _.some(aistatusValue.data(), (ele, idx) => {
      return (_.isBoolean(ele) && ele)
    })
  }

  useEffect(() => { // get the customer names..
    let listeners = [];
    _.each(context.customerIdList, (e) => {
      listeners.push(firebase.firestore().collection('customerdata').doc(e).onSnapshot(function(querySnapshot) {
        setCustomerList((customerList) => {
          customerList[e] = querySnapshot.data().name;
          return customerList;
        });
      }));
    })
    return () => {
      _.each(listeners, (unsubcribe) => unsubcribe()); // cleanup our event listeners.
    }
  // context.customerIdList is not statically typed, hence supress the error here...
  // eslint-disable-next-line
  }, context.customerIdList);

  function handleDrawerOpen() {
    context.setIsDrawerOpen(true);
  }

  function handleDrawerClose() {
    context.setIsDrawerOpen(false);
  }

  function handleProductMenuOpen() {
    if (context.isDrawerOpen) {
      setProductMenuOpen(true)
    } else {
      handleDrawerOpen();
      setTimeout(()=>setProductMenuOpen(true), 150);
    }
  }

  function handleProductMenuClose() {
    setProductMenuOpen(false);
  }

  function handleCustomerMenuOpen() {
    if (context.isDrawerOpen) {
      setCustomerMenuOpen(true)
    } else {
      handleDrawerOpen();
      setTimeout(()=>setCustomerMenuOpen(true), 150);
    }
  }

  function handleCustomerMenuClose() {
    setCustomerMenuOpen(false);
  }

  function handleProductMenuSelection(event) {
    context.deviceSettings.ref.update({
      selectedProductId: event.target.value
    })
    .then(function(){
      console.log("set selectedProductId");
    })
    .catch(function(error){
      console.error('error setting selectedProductId: ', error);
    });
  }

  function handleManualTrigger() {
    console.log('requesting image...');
    context.socket.emit('getImage', {'type': 'scan'});
  }


  let isManualTrigger = context.deviceSettings.data().isManualTrigger;

  return <Drawer
    variant="permanent"
    className={clsx(classes.drawer, {
      [classes.drawerOpen]: context.isDrawerOpen,
      [classes.drawerClose]: !context.isDrawerOpen,
    })}
    classes={{
      paper: clsx({
        [classes.drawerOpen]: context.isDrawerOpen,
        [classes.drawerClose]: !context.isDrawerOpen,
      }),
    }}
    open={context.isDrawerOpen}
  >
    <div className={classes.toolbar}>
      {context.isDrawerOpen ?
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        :
        <IconButton onClick={handleDrawerOpen}>
          <ChevronRightIcon />
        </IconButton>
      }
    </div>



    <List key={"list1"}>
      <Route path="/(|inspection)" children={({match}) => {return (
        <ListItem button key={"inspection"} onClick={()=>{browserhistory.push('/inspection')}} selected={match ? true: false}>
          <ListItemIcon><InspectionIcon color="primary" /></ListItemIcon>
          <ListItemText primary={t('Inspection')} />
        </ListItem>
      )}} />
      <Route path="/history" children={({match}) => {return (
        <ListItem button key={"history"} onClick={()=>{browserhistory.push('/history')}} selected={match ? true: false}>
          <ListItemIcon><HistoryIcon /></ListItemIcon>
          <ListItemText primary={t("History")} />
        </ListItem>
      )}} />
    </List>
    <Divider />
    {_.size(context.customerIdList) > 1 &&
      <ListItem key={"customer"} >
        <ListItemIcon onClick={handleCustomerMenuOpen}><CustomerIcon  /></ListItemIcon>
        <ListItemText key={"customertext"}>
          <Select
              style={{maxWidth: '100%'}}
              value={context.selectedCustomerId}
              open={customerMenuOpen}
              onClose={handleCustomerMenuClose}
              onOpen={handleCustomerMenuOpen}
              onChange={context.setSelectedCustomerId}
            >
          {
            _.map(context.customerIdList, (e) => {
              return <MenuItem key={e} value={e}>{customerList[e]}</MenuItem>
            })
          }
          </Select>
        </ListItemText>
      </ListItem>
    }

    { (!productsLoading && productsValue && _.size(productsValue.docs) > 1) &&
      <ListItem key={"product"}>
        <ListItemIcon onClick={handleProductMenuOpen} ><ProductsIcon  /></ListItemIcon>
        <ListItemText key="producttext">
        <Select
          style={{maxWidth: '100%'}}
          value={context.deviceSettings.data().selectedProductId}
          open={productMenuOpen}
          onClose={handleProductMenuClose}
          onOpen={handleProductMenuOpen}
          onChange={handleProductMenuSelection}
        >
          {
            _.map(productsValue.docs, (doc) => {
              return (<MenuItem key={doc.id} value={doc.id}>
                <ListItemIcon
                  style={{minWidth: '40px'}}
                  >
                  {
                    _.some(doc.data().regions, (region) => { // EdgeMode is off if any associated task has not its aiSettings.isEdgeMode set to true (implying as well if the aiSettings or isEdgeMode are missing)
                    return _.some(region.tasks, (taskPath, taskId) => {
                      return _.find(context.taskDocs.docs, (o) => {
                        return (o.id===taskId && _.get(o.data(), 'aiSettings.isEdgeMode') !== true)
                      })
                    })

                    // _.get(doc.data, 'aiSettings.isEdgeMode')
                  }) ?
                  <AiCloudIcon /> : <AiEdgeIcon />
                  }
                </ListItemIcon>
                {doc.data().name}
                </MenuItem>);
            })
          }
        </Select>
        </ListItemText>
      </ListItem>
    }
    <Divider />
    <List key={"list2"}>
      <Route path="/settings" children={({match}) => {return (
        <ListItem button key={"settings"} onClick={()=>{browserhistory.push('/settings')}} selected={match ? true: false}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={t("Settings")} />
        </ListItem>
      )}} />
    </List>


    <List className={classes.bottomlist}>
      {!isAIOn &&
        <ListItem key={"aioffline"}>
          <ListItemIcon><AIStatusOffIcon color="secondary"/></ListItemIcon>
          <ListItemText>
            <Chip variant="outlined" color="secondary" label={t('Cloud AI Offline')}/>
          </ListItemText>
        </ListItem>
      }
      <Offline>
        <ListItem key={"offline"}>
          <ListItemIcon><OfflineIcon color="primary"/></ListItemIcon>
          <ListItemText>
            <Chip variant="outlined" color="primary" label={t('Device Offline')}/>
          </ListItemText>
        </ListItem>
      </Offline>
      {isManualTrigger &&
        <React.Fragment>
          <ListItem button onClick={handleManualTrigger} key={"take image"} className={classes.manualTrigger}>
            <ListItemIcon><ManualTriggerIcon className={classes.manualTriggerIcon} /></ListItemIcon>
            <ListItemText primary={t("Take Image")} />
          </ListItem>

          {
          // <ListItem key={"manual mode"}>
          //   <ListItemIcon><ManualTriggerModeIcon color="primary"/></ListItemIcon>
          //   <ListItemText>
          //     <Chip variant="outlined" color="primary" label={t('Manual Mode')}/>
          //   </ListItemText>
          // </ListItem>
          }
        </React.Fragment>
      }

    </List>


  </Drawer>
}

export default CustomDrawer;
