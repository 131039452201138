import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/ExitToApp';

import Slide from '@material-ui/core/Slide';



const useStyles = makeStyles(theme => ({
  closebutton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  content: {
    paddingTop: theme.spacing(6),
  },
}));



function FullscreenDialog(props) {
  const classes = useStyles();


  if (props.useTransition === false) {
    return <Dialog fullScreen open={true} onClose={props.handleClose}>
              {!props.hideClose &&
                <div className={classes.closebutton}>
                    <IconButton aria-label="close" onClick={props.handleClose}>
                      <CloseIcon />
                    </IconButton>
                </div>
              }
              {props.children}
            </Dialog>;

  } else {
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
    return <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
              {!props.hideClose &&
                <div className={classes.closebutton}>
                    <IconButton aria-label="close" onClick={props.handleClose}>
                      <CloseIcon />
                    </IconButton>
                </div>
              }
              {props.children}
            </Dialog>;
    }

}

export default FullscreenDialog;
