import React from 'react';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useTranslation } from 'react-i18next';

import Keyboard from './Keyboard';


export default function FormDialog(props) {

  const { t } = useTranslation();
  const keyboardRef = React.useRef();

  const [name, setName] = React.useState(props.name);

  const handleSave = () => {
    if (props.doc) {
      props.handleSave(props.doc, name);
    } else {
      props.handleSave(name);
    }
  };

  const handleDelete = () => {
    if (props.doc) {
      props.handleDelete(props.doc);
    } else {
      props.handleDelete();
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleChange = (event) => {
    setName(event.target.value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(event.target.value, 'editDialog');
    }
  }

  const handleKeyboardChange = (input) => {
    setName(input);
  }



  return (
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
        <DialogContent style={{minWidth: '960px'}} >
          <TextField
            autoFocus
            value={name}
            onChange={handleChange}
            margin="dense"
            id="name"
            label={props.labelText ? props.labelText : t('Name')}
            fullWidth
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') { // use enter to save
                  handleSave();
                  ev.preventDefault();
                }
              }}
          />
          { props.hint &&
            <Typography color="secondary">{props.hint}</Typography>
          }
          { props.showKeyboard &&
            <Keyboard
              input={name}
              inputName={'editDialog'}
              keyboardRef={keyboardRef}
              onChange={handleKeyboardChange}
            />
          }
        </DialogContent>
        <DialogActions>
          {props.handleDelete &&
            <Button onClick={handleDelete} color="secondary">
              {props.deleteText ? props.deleteText : t('Delete')}
            </Button>
          }
          {props.handleClose &&
          <Button onClick={handleClose} color="primary">
            {props.cancelText ? props.cancelText : t('Cancel')}
          </Button>
          }
          {props.handleSave &&
            <Button onClick={handleSave} color="primary">
              {props.saveText ? props.saveText : t('Save')}
            </Button>
          }
        </DialogActions>

      </Dialog>

  );
}
