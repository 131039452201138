import React from 'react';

import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  stepper: {
    padding: '0px',
  },
  button: {
    padding: theme.spacing(1.5, 1),
  },

}));


export default function Stepper(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MobileStepper
      className={classes.stepper}
      variant={props.regionCount > 5 ? "text" : "dots"}
      steps={props.regionCount}
      position="static"
      activeStep={props.activeRegionIdx}
      nextButton={
        <Button size="small" className={classes.button} onClick={props.handleNext} disabled={props.activeRegionIdx >= props.regionCount-1}>
          {t('Next Region')}
          <KeyboardArrowRightIcon />
        </Button>
      }
      backButton={
        <Button size="small" className={classes.button} onClick={props.handleBack} disabled={props.activeRegionIdx <= 0}>
          <KeyboardArrowLeftIcon />
          {t('Last Region')}
        </Button>
      }
    />
  );
}
