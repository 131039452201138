import React, {useContext, useState, useRef, useEffect, useCallback} from 'react';

import { useTranslation } from 'react-i18next';

import { Stage, Layer, Rect, Image as KonvaImage } from 'react-konva';

import useImage from './base/useImage';

import _ from 'lodash';

import Typography from '@material-ui/core/Typography';


import { Context } from '../Context';
import RegionDetail from './RegionDetail';
import LinearProgress from '@material-ui/core/LinearProgress';

import RejectedIcon from '@material-ui/icons/NotInterested';
import AcceptedIcon from '@material-ui/icons/Check';
import UnclassifiedIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/ExitToApp';

import { makeStyles } from '@material-ui/core/styles';

import {ACCEPTED_COLOR, REJECTED_COLOR, UNCLASSIFIED_COLOR} from '../Defines';
const useStyles = makeStyles(theme => ({
  stageContainer: {
    width: '100%', // NOTE: the outside container should have a clear height and width set
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rejected: {
    height: '28px',
    backgroundColor: REJECTED_COLOR,
    alignItems: 'center',
    display: 'flex',
  },
  accepted: {
    height: '28px',
    backgroundColor: ACCEPTED_COLOR,
    alignItems: 'center',
    display: 'flex',
  },
  unclassified: {
    height: '28px',
    backgroundColor: UNCLASSIFIED_COLOR,
    alignItems: 'center',
    display: 'flex',
  },
  classificationFps:{
    flexGrow: 1,
    textAlign: 'start',
    margin: theme.spacing(0.5),
  },
  classificationIconContainer:{
    lineHeight: '2px', // strange.. but helps
    textAlign: 'end',
  },
  classificationIcon: {
    margin: theme.spacing(0.5),
  },
  progress: {
    marginBottom: theme.spacing(-0.5),
  },
  imageContainer: {
    position: 'relative',
  },
  noTask: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#FFC50B',
    color: 'black',
    padding: theme.spacing(1),
    zIndex: 1,
    borderRadius: theme.spacing(0.5),
    display: 'flex',
  },
  warnIcon: {
    marginRight: theme.spacing(0.5),
  },
  closebutton: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0, // the button is hidden..
  },
}));


function InspectionImage (props) {
  const { t } = useTranslation();
  const context = useContext(Context);
  const [resizeCounter, setResizeCounter] = useState(0);
  const [tasksLastUpdatedAt, setTasksLastUpdatedAt] = useState(null);

  const stageRef = useRef(null);
  const classes = useStyles();


  const [regionTaskDetailIdx, setRegionTaskDetailIdx] = useState(null);

  const [size, setSize] = useState({width: 640, height: 480, scale: {x: 1, y: 1}});

  let resolution = {width: 640, height: 480};
  if (props.image && props.image.resolution) {
    resolution = props.image.resolution
  }

  console.log("RESOLUTION InspectionImage: ")
  console.log(resolution)

  let src = "";


  // we use the effect to get our resizerCallback called again..
  useEffect(() => {
    function resizer () {
      setResizeCounter(c => c + 1);
    }
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  }, []);

  // we use the effect to make sure we update when we have an taskImage update...
  useEffect(() => {
    let tmpTasksLastUpdatedAt = null;
    if (props.image) {
      _.each(props.image.regions, (r, rIdx) => {
        _.each(r.tasks, (t) => {
          if (tmpTasksLastUpdatedAt === null || tmpTasksLastUpdatedAt < t._updatedAt) {
            tmpTasksLastUpdatedAt = t._updatedAt;
          }
        });
      });
      if (tasksLastUpdatedAt === null || tmpTasksLastUpdatedAt > tasksLastUpdatedAt)
      {
        setTasksLastUpdatedAt(tmpTasksLastUpdatedAt);
      }
    }
  //eslint-disable-next-line
}, [props.image, tasksLastUpdatedAt]); // NOTE: prop.simage may be a bit tricky...  lets do it for now..

  const resizerCallback = useCallback((node) => {
    if (node !== null) {
      var scale = Math.min(
        node.offsetWidth / resolution.width,
        node.offsetHeight / resolution.height,
      );
      console.log('calc:', node.offsetWidth / resolution.width, node.offsetHeight / resolution.height)
      console.log('scale', scale)
      setSize({width: resolution.width*scale, height:resolution.height*scale, scale: {x: scale, y:scale}})
    }
  // eslint-disable-next-line
  }, [resizeCounter, resolution.width, resolution.height, context.isDrawerOpen]);  // we want the resizeCounter here, as well as isDrawerOpen as both can influence offsetWidth...


  if (!props.image)
  {

  } else {
    src = `data:image/${props.image.imageType};base64,${props.image.imageData}`
  }
  const [currentImage] = useImage(src);
  // const [backgroundImage, backgroundImageStatus] = useImage(doc ? doc.data().imageURL : null, doc ? doc.ref.id + '_bg' : null); // second argument for cors

  let classificationStatus;
  let classificationClass;
  if (props.image) {
    classificationStatus = 'accepted';
    classificationClass = classes.accepted;
    _.each(props.image.regions, (r, rIdx) => {
      _.each(r.tasks, (t) => {
        switch (t.classification) {
          case 'rejected':
            classificationStatus = 'rejected';
            classificationClass = classes.rejected;
            break;
          case 'accepted':
            break;
          default:
            if (classificationStatus === 'accepted') { // set unclassified if we have not 'rejected'. basically if anything sofar is accepted and we went with unclassified
              classificationStatus = 'unclassified';
              classificationClass = classes.unclassified;
            }
            break;
        }
      })
    });
  }


  function handleRegionDetailClose() {
    setRegionTaskDetailIdx(null);
    // NOTE: we use the counter to get a redraw as apparently the ARM Browser has a bug of sometimes not refreshing the shown image...
    setTimeout(
      () => setResizeCounter(c => c + 1),
      150
    );
  }
  let counter = -1; // we increment the counter before use, hence -1 to start with 0 indexed..
  // let styleTmp = context.isDrawerOpen ? 'calc(100vw - 288px)' : 'calc(100vw - 118px)';

  // NOTE: we use a hidden close button here, as apparently the closing from within RegionDetail does not work always on the arm browser. This seems so far like a browser bug...
  return (
        <div ref={resizerCallback} className={classes.stageContainer}>
          <div className={classes.closebutton}>
              <IconButton aria-label="close" onClick={() => setRegionTaskDetailIdx(null)}>
                <CloseIcon />
              </IconButton>
          </div>
          { regionTaskDetailIdx !== null &&
            <RegionDetail setRegionTaskDetailIdx={setRegionTaskDetailIdx} image={props.image} regionTaskDetailIdx={regionTaskDetailIdx} handleClose={handleRegionDetailClose} />
          }
          { _.isUndefined(props.image) &&
            <Typography>{t('No image')}</Typography>
          }
          { (!_.isUndefined(props.image) && regionTaskDetailIdx === null) &&
            <React.Fragment>
              <div className={classes.imageContainer}>

                { (_.some(props.image.regions, (region, rIdx) => {
                    return _.some(region.tasks, (t, tIdx) => {
                      return _.isUndefined(t.taskImageId)
                      })
                    }) || !props.image.isImageUploaded) && props.image.shouldUpload &&
                  <LinearProgress className={classes.progress} />
                }
                <div className={classificationClass}>
                  <Typography className={classes.classificationFps} style={{color: classificationStatus === 'unclassified' ? 'black':'white' }}>
                      {props.fps ? props.fps + ' ' + t('fps') : ''}
                  </Typography>
                  {classificationStatus === 'accepted' &&
                    <div className={classes.classificationIconContainer}>
                      <AcceptedIcon fontSize='small' className={classes.classificationIcon} />
                    </div>
                  }
                  {classificationStatus === 'rejected' &&
                    <div className={classes.classificationIconContainer}>
                      <RejectedIcon  fontSize='small' className={classes.classificationIcon} />
                    </div>
                  }
                  {classificationStatus === 'unclassified' &&
                    <div className={classes.classificationIconContainer}>
                      <UnclassifiedIcon style={{color:'black'}} fontSize='small' className={classes.classificationIcon} />
                    </div>
                  }
                </div>
                {
                  !_.some(props.image.regions, (region, rIdx) => { return _.size(region.tasks) > 0 }) &&
                  <div className={classes.noTask}>
                    <WarningIcon fontSize='small' className={classes.warnIcon}/>
                    {t('no task')}
                    </div>
                }
                <Stage
                  ref={stageRef}
                  width={size.width}
                  height={size.height}
                  scale={size.scale}
                >
                  <Layer>
                    <KonvaImage
                      image={currentImage}
                      width={resolution.width}
                      height={resolution.height}
                      />
                    { (context.selectedProduct && context.selectedProduct.data()) &&
                      _.map( // then return only the content - size was only needed for sorting
                        _.orderBy( // then order them by rect size desc
                          _.map(props.image.regions, (region, regionIdx) => { // first create our rects
                            let UNCLASSIFIED = 1;
                            let ACCEPTED = 2;
                            let REJECTED = 4;
                            // search for data abbout this region / task in our image
                            let status = 0; // binary status
                            _.each(region.tasks, (t) => {
                              counter++;
                                switch (t.classification) {
                                  case 'accepted':
                                    status = status | ACCEPTED;
                                    break;
                                  case 'rejected':
                                    status = status | REJECTED;
                                    break;
                                  default:
                                    status = status | UNCLASSIFIED;
                                    break;
                                }
                            });
                            // handle different variants
                            let dashes = [];
                            if (status & ACCEPTED) {
                              dashes.push(ACCEPTED_COLOR)
                            }
                            if (status & REJECTED) {
                              dashes.push(REJECTED_COLOR)
                            }
                            if (status & UNCLASSIFIED) {
                              dashes.push(UNCLASSIFIED_COLOR)
                            }

                            // NOTE: partial is used here to store local state.. and local function to avoid partial error with hooks
                            return {size: (region.right - region.left) * (region.bottom - region.top),
                                    content:_.map(dashes, (e, idx) => {
                              let dashappart = 90 / idx / _.size(dashes);
                              let dashlength = 90 / idx / _.size(dashes);
                              return (<Rect
                                  key = {idx}
                                  x={region.left}
                                  y={region.top}
                                  width={region.right - region.left}
                                  height={region.bottom - region.top}
                                  stroke={e}
                                  strokeWidth={Math.ceil(resolution.width / (1200 / 3))} // make the strokewidth resolution dependant - or indepentent on the display
                                  shadowForStrokeEnabled={true}
                                  shadowColor='black'
                                  shadowOffset={{x:0, y:0}}
                                  shadowOpacity={1}
                                  shadowBlur={18}
                                  dash={[dashappart, dashlength]}
                                  dashEnabled={_.size(dashes) > 1}
                                  onClick={_.partial((c) => setRegionTaskDetailIdx(c), counter + 1 - _.size(region.tasks) )}
                                  onTap={_.partial((c) => setRegionTaskDetailIdx(c), counter + 1 - _.size(region.tasks) )}
                                />);
                            })};
                          })
                        , (o) => {return o.size}, 'desc')
                      , (o) => {return o.content})
                    }
                  </Layer>
                </Stage>
              </div>
            </React.Fragment>
          }
        </div>
  );
}

export default InspectionImage;
