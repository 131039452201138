import React from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Keyboard from './base/Keyboard';


import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },


  dialogDataHeader: {
    marginBottom: theme.spacing(1),
  },
  dialogEditHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  dialogEditDiv: {
    marginTop: theme.spacing(3),
  },
  dialogEditSubDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  halfTextField: {
    marginRight: theme.spacing(2),
  }
}));


function EditPreprocessingDialog(props) {
  const keyboardRef = React.useRef();

  const classes = useStyles();
  const { t } = useTranslation();

  const [errorText, setErrorText] = React.useState([]);

  const [currentInput, setCurrentInput] = React.useState(null);


  const handleNameChange = (event) => {
    if (props.deviceDoc) {
      props.deviceDoc.ref.update({
        name: event.target.value,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }).then((res) => {setErrorText([]); console.log('name updated')});
    }
  }

  const handlePreprocessingChange = (type, key, value) => {
    if (props.deviceDoc && type && key) {
      let updateObj = {
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      switch(type) {
        case 'boolean':
          updateObj['preprocessing.' + key] = !(props.deviceDoc.data().preprocessing[key]);
          console.log(updateObj);
          props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          break;
        case 'number':
          if (!isNaN(value)) {
            updateObj['preprocessing.' + key] = _.toNumber(value);
            props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          } else {
            setErrorText ((text) =>  _.union(text, ["Unable to update. Input is not a number. Field: " + key]));
          }
          break;
        case 'string':
          updateObj['preprocessing.' + key] = value;
          props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          break;
        default:
        console.log("Type: " + typeof value)
          setErrorText ((text) =>  _.union(text, ["Unable to update. Currently only boolean, number and string are supported. Field: " + key]));
          break;
      }
    }
  }

  const handlePreprocessingInputChange = (type, key, event) => {
    handlePreprocessingChange(type, key, event.target.value);
  }

  const handleKeyboardChange = (value) => {
    let explo = _.split(currentInput, '|');
    handlePreprocessingChange(explo[1], explo[0], value)
  }


  const handleDialogClose = () => {
    props.handleDialogClose();
  }

  const gridName = 2;
  const gridContent = 12 - gridName;



  return (<Dialog
      open
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth='md'
      >
      <DialogTitle id="form-dialog-title">{t('Preprocessing Settings for {{deviceName}}', {'deviceName': props.deviceDoc.data().name} )}</DialogTitle>

      <DialogContent>
        <Typography color="primary" variant="caption">{t('Edits are immediately saved')}</Typography>
        <div className={classes.dialogEditDiv}>
        {
          _.map(_.orderBy(_.map(props.deviceDoc.data().preprocessing, (prepValue, prepKey) => {
            let ret = {}
            ret['key'] = prepKey;
            ret['value'] = prepValue;
            ret['content'] =(
              <div className={classes.dialogEditSubDiv} key={'prep_' + prepKey}>
              { _.isBoolean (prepValue) &&
                <FormControlLabel
                  control={
                    <Switch checked={prepValue === true} color="primary" onChange={_.partial(handlePreprocessingInputChange, 'boolean', prepKey)} />
                  }
                  label={prepKey}
                />
              }
              { !_.isBoolean (prepValue) && _.isNumber (prepValue) &&
                <TextField id="device-name" label={prepKey} value={prepValue} onChange={_.partial(handlePreprocessingInputChange, 'number', prepKey)} fullWidth onFocus={()=>{setCurrentInput(prepKey + '|number'); keyboardRef.current.setInput(prepValue +"", prepKey + '|number')}}/>
              }
              { !_.isBoolean (prepValue) && !_.isNumber (prepValue) && _.isString (prepValue) &&
                <TextField id="device-name" label={prepKey} value={prepValue} onChange={_.partial(handlePreprocessingInputChange, 'string', prepKey)} fullWidth onFocus={()=>{setCurrentInput(prepKey + '|string'); keyboardRef.current.setInput(prepValue +"", prepKey + '|string')}}/>
              }

              </div>
            );
            return ret;
          }),
          // order by..
          [(o) => {
            if (_.isBoolean(o.value)) return 1;
            if (_.isString(o.value)) return 2;
            if (_.isNumber(o.value)) return 3;
            return 4
          },
          (o) => o.key]
        ), (e) => e.content)
        }
        </div>

        <Keyboard
          input={""}
          inputName={currentInput}
          keyboardRef={keyboardRef}
          onChange={handleKeyboardChange}
        />
        { _.size(errorText) > 0 &&
          <div>
            <Typography variant="caption" color="secondary">
              {_.join(errorText)}
            </Typography>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>);
}


export default EditPreprocessingDialog;
