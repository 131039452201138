import React, { createContext, useState, useEffect, useReducer, useRef } from "react";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { useCollection } from 'react-firebase-hooks/firestore';

import _ from 'lodash';

import { HISTORY_LENGTH, NETWORKSETTINGSINIT } from './Defines';




function deepCompareEquals(a, b){
  // TODO: implement deep comparison here
  // something like lodash
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

// the grab the data out of a returned firebase request. if  .data() is not avaiable just return the input
function docToData (doc) {
  if (doc && _.isFunction(doc.data)) {
    return doc.data();
  }
  return doc;
}

function docArrayToData (docs) {
  return _.map(docs, (doc) => {
    return docToData(doc);
  });
}







export const Context = createContext({});

export const Provider = props => {
  // Initial values are obtained from the props
  const socket = props.initialSocket; // const [socket, setSocket] = useState(props.initialSocket);
  const customerIdList = props.initialCustomerIdList; // const [customerIdList, setCustomerIdList] = useState(props.initialCustomerIdList);

  // Use State to keep values which may change from bellow.
  const [selectedCustomerId, setSelectedCustomerIdHook] = useState(props.initialSelectedCustomerId);
  const [deviceId, setDeviceId] = useState(props.initialDeviceId);
  const [selectedProductId, setSelectedProductIdHook] = useState('-1');
  const [networkSettings, setNetworkSettings] = useState(NETWORKSETTINGSINIT);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [fpsObject, setFpsObject] = useState();
  // const [isUpdateAvailable, setIsUpdateAvailable] = useState(props.isUpdateAvailable);


  function processTaskImageUpdate(state, taskImageDocs) {
    console.log('processTaskImageUpdate taskImageUpdate');

    let newState = _.cloneDeep(state);


    let shouldUpdate = false;
    _.each(taskImageDocs, (taskImageDoc) => {
      let taskImage = taskImageDoc.data();
      // console.log('iterating docs... for ' + taskImage.imageId);

      let idx = _.findIndex(newState, (o) => {return o.imageId === taskImage.imageId;}); // check if we still have the image in our history and find the id..

      if (idx !== -1 ) {
        // console.log('FOUND A DOC..');
        _.each(newState[idx].regions, (r, rIdx) => {
          // do we have a region match?
          if (r.top === taskImage.region.top &&
              r.left === taskImage.region.left &&
              r.bottom === taskImage.region.bottom &&
              r.right === taskImage.region.right
              ){
            _.each(r.tasks, (t, tIdx) => {
              if (tIdx === taskImage.taskId) { // do we have a task match?
                if (taskImage.classification !== newState[idx].regions[rIdx].tasks[tIdx].classification && taskImage.classification) {
                  // console.log('updating classification and updating taskimageId: ' + newState[idx].taskImageId + ' -> ' + taskImageDoc.id);
                  // lets emit the result to our hardware layer
                  let emitClassification = {
                    'imageId': newState[idx].imageId,
                    'taskId': taskImage.taskId,
                    'region': {
                      top: r.top,
                      left: r.left,
                      bottom: r.bottom,
                      right: r.right
                    },
                    classification: taskImage.classification,
                  }
                  socket.emit('classification', emitClassification);
                  _.set(newState[idx], 'regions[' + rIdx + '].tasks[' + tIdx + '].classification', taskImage.classification);
                  _.set(newState[idx], 'regions[' + rIdx + '].tasks[' + tIdx + '].taskImageId', taskImageDoc.id);
                  shouldUpdate = true;
                } else {
                  if (newState[idx].taskImageId !== taskImageDoc.id) {
                    // console.log('updating taskimageId: ' + newState[idx].taskImageId + ' -> ' + taskImageDoc.id);
                    _.set(newState[idx], 'regions[' + rIdx + '].tasks[' + tIdx + '].taskImageId', taskImageDoc.id);

                    shouldUpdate = true;
                  }
                }
                if(taskImage.markImage !== newState[idx].regions[rIdx].tasks[tIdx].markImage) {
                  console.log('updating markImage');
                  _.set(newState[idx], 'regions[' + rIdx + '].tasks[' + tIdx + '].markImage', taskImage.markImage);
                  shouldUpdate = true;
                }
                if(taskImage._updatedAt !== newState[idx].regions[rIdx].tasks[tIdx]._updatedAt) {
                  console.log('updating _updatedAt');
                  _.set(newState[idx], 'regions[' + rIdx + '].tasks[' + tIdx + ']._updatedAt', taskImage._updatedAt);
                  shouldUpdate = true;
                }
              }
            })
          }
        });
      }
    });
    if (shouldUpdate) {
      return newState;
    } else {
      return state;
    }
  }


//  const HISTORY_LENGTH = 20;
  function imageHistoryReducer(state, data) { // NOTE: reducer could be called several times by react.
    if (data.operationType === 'clear') {
      return [];
    }
    if (data.operationType === 'taskImageUpdate') {
      console.log('imageHistoryReducer taskImageUpdate');
      return processTaskImageUpdate(state, data.data);
    }
    let image = data.data;
    // console.log(' imageHistoryReducer');
    // console.log(data);
    // console.dir(state);
    // console.dir(image);
    let tmp = _.cloneDeep(state); // make sure we do not modify state here..
    let idx = _.findIndex(state, (o) => {return o.imageId === image.imageId});

    if (idx === -1) {// not found - lets add
      if (data.operationType === 'merge' || data.operationType === 'classification') {
        console.warn('imageHistoryReducer IDX NOT FOUND FOR MERGE');
        return state;
      }
      // console.log('imageHistoryReducer shifting image with id: ' + image.imageId);
      // console.log(state);
      tmp.unshift(image); // add the image to the beginning
      return _.slice(tmp, 0, HISTORY_LENGTH); // cut down the length to HISTORY_LENGTH
    } else { // found - lets update
      // console.log('imageHistoryReducer udpating image with id: ' + image.imageId + ' operationType: ' + data.operationType);
      switch(data.operationType) {
        case 'classification':
          let regionIdx = _.findIndex(tmp[idx].regions,
            (o) => {
              return  o.top === data.classification.region.top &&
                      o.left === data.classification.region.left &&
                      o.bottom === data.classification.region.bottom &&
                      o.right === data.classification.region.right
            });
          if (regionIdx === -1) {
            console.warn('imageHistoryReducer classification region not found');
            return tmp;
          }
          if (!tmp[idx].regions[regionIdx].tasks[data.classification.taskId]) {
            console.warn('imageHistoryReducer classification task not found for taskId:' + data.classification.taskId);
            return tmp;
          }
          tmp[idx].regions[regionIdx].tasks[data.classification.taskId].classification = data.classification.classification;
          return tmp;
          // eslint-disable-next-line
          break; // not reachable, but lets keep it here to have the case include a break..
        case 'merge':
          _.merge(tmp[idx], image);
          return tmp;
          // eslint-disable-next-line
          break; // not reachable, but lets keep it here to have the case include a break..
        default:
        case 'overwrite':
          tmp[idx] = image;
          return tmp;
          // eslint-disable-next-line
          break; // not reachable, but lets keep it here to have the case include a break..
      }
    }
  }
  const [imageHistory, updateImageHistory] = useReducer(imageHistoryReducer, []);



  function historyViewReducer(state, data) {
    if (data.operationType === 'taskImageUpdate') {
      console.log('imageHistoryReducer taskImageUpdate');
      return processTaskImageUpdate(state, data.data);
    }

    let tmp = _.cloneDeep(state); // make sure we do not modify state here..
    let idx = -1;


    switch (data.operationType) {
      case 'clear':
        return [];
      case 'set':
        return data.data;
        // eslint-disable-next-line
        break; // not reachable, but lets keep it here to have the case include a break..

      case 'classification':
        idx = _.findIndex(state, (o) => {return o.imageId === data.data.imageId});
        if (idx === -1) {
          // console.warn('historyViewReducer IDX NOT FOUND FOR MERGE');
          return state;
        }
        let regionIdx = _.findIndex(tmp[idx].regions,
          (o) => {
            return  o.top === data.classification.region.top &&
                    o.left === data.classification.region.left &&
                    o.bottom === data.classification.region.bottom &&
                    o.right === data.classification.region.right
          });
            if (regionIdx === -1) {
          console.warn('historyViewReducer classification region not found');
          return tmp;
        }
        if (!tmp[idx].regions[regionIdx].tasks[data.classification.taskId]) {
          console.warn('historyViewReducer classification task not found for taskId:' + data.classification.taskId);
          return tmp;
        }
        tmp[idx].regions[regionIdx].tasks[data.classification.taskId].classification = data.classification.classification;
        return tmp;
        // eslint-disable-next-line
        break; // not reachable, but lets keep it here to have the case include a break..

      case 'merge':
        idx = _.findIndex(state, (o) => {return o.imageId === data.data.imageId});
        if (idx === -1) {
          // console.warn('historyViewReducer IDX NOT FOUND FOR MERGE');
          return state;
        } else {
          _.merge(tmp[idx], data.data);
          return tmp;
        }
        // eslint-disable-next-line
        break; // not reachable, but lets keep it here to have the case include a break..
      default:
        console.error(' historyViewReducer called without operationType');
        console.warn('data, state', data, state);
    }
  }
  const [historyView, updateHistoryView] = useReducer(historyViewReducer, []);


  const setSelectedCustomerId = (e, value) => {
    setSelectedCustomerIdHook(value.props.value);
  };


  function setSelectedProductId (productId) {
    if (productId !== selectedProductId) {
      console.log('CHANGING PRODUCT ID to: ' + productId);
      // clear histories if we select a new product
      updateHistoryView({operationType: 'clear'});
      updateImageHistory({operationType: 'clear'});
      setSelectedProductIdHook(productId);
    }
  }


  // get our networkSettings
  useEffect (() => {
    console.log("Getting networkSettings");
    const listener = (payload) => {
      console.log('got networkSettings');
      console.log(payload);
      setNetworkSettings(payload);
    };
    socket.on('networkSettings', listener);

    socket.emit('getNetworkSettings', {});
    return  () => {
      console.log('removing networkSettings listener - useEffect CLEANUP');
      socket.off('networkSettings', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing



  // get our fps
  useEffect (() => {
    console.log("Getting fpsObject");
    const listener = (payload) => {
      console.log('got fpsObject');
      setFpsObject(payload);
    };
    socket.on('fps', listener);
    return  () => {
      console.log('removing fpsObject listener - useEffect CLEANUP');
      socket.off('fps', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing


  const [tasksValue, tasksLoading, tasksError] = useCollection(
    firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('tasks')
  );
  if (tasksError) console.error(tasksError);

  //
  const [deviceSettingsValue, deviceSettingsLoading, deviceSettingsError] = useCollection(
    firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('devices').doc(deviceId)
  );
  if (deviceSettingsError) console.error(deviceSettingsError);

  const setDeviceSettings = (settings) => {
    if (deviceSettingsValue && deviceSettingsValue.ref) {
      deviceSettingsValue.ref.update(
        {
          ...settings,
          _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      ).then((data) => {
      });
    }
  };

  let deviceSettingsData = {};
  if (!deviceSettingsLoading && deviceSettingsValue) {
    if (deviceSettingsValue.data()) {
      deviceSettingsData = deviceSettingsValue.data() || {};
    }
  }

  // emit deviceSettings and respond to getDeviceSettings
  useDeepCompareEffect (() => {
    if (!deviceSettingsLoading && deviceSettingsValue) {
      if (deviceSettingsValue.data()) {
        // console.log('deviceSettings:');
        // console.log(deviceSettingsValue);
        // console.log(deviceSettingsValue.data());
        setSelectedProductId (deviceSettingsValue.data().selectedProductId || '-1');
        console.log("socket setDeviceSettings");
        socket.emit('setDeviceSettings', deviceSettingsValue.data()); // this will be triggered multiple times, as the _updatedAt is a server timestamp. we could use _hasPendingWrites to only go for fully commited ones, but probably not neccessary
      }
    }

    const listener = (payload) => {
      console.log("on getDeviceSettings");
      if (deviceSettingsValue) {
        console.log('got getDeviceSettings, emitting setDeviceSettings');
        socket.emit('setDeviceSettings', deviceSettingsValue.data()); // this will be triggered multiple times, as the _updatedAt is a server timestamp. we could use _hasPendingWrites to only go for fully commited ones, but probably not neccessary
      } else {
        console.warn('got getDeviceSettings, NOT emitting setDeviceSettings - deviceSettingsValue not set');
      }
    };
    socket.on('getDeviceSettings', listener);

    return  () => {
      console.log('removing getDeviceSettings listener - useEffect CLEANUP');
      socket.off('getDeviceSettings', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
}, [docToData(deviceSettingsValue)]); // we rely upon the socket not changing


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // MAKE SURE WE HAVE A CORRECT SELECTED PRODUCT
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [productsValue, productsLoading, productsError] = useCollection(
    firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('products')
  );
  if (productsError) console.error(productsError);
  // make sure we have a selectedProductId which is valid - if we can..
  if (!productsLoading && productsValue) {
    if (selectedProductId === '-1') { // no selected product.
      let doc = productsValue.docs[0];
      if (doc && doc.id) {
        console.warn('Fixing selectedProductId === \'-1\' -> setting to: ' + doc.id);
        setDeviceSettings({selectedProductId: doc.id});
        setSelectedProductId(doc.id);
      }
    } else {
      // make sure the selected product is valid
      let found = _.find(productsValue.docs,
        (o) => {return o.id === selectedProductId}
      );
      if (_.isUndefined(found)) {
        console.warn('Fixing selectedProductId not found in products -> setting to: \'-1\'');
        setDeviceSettings({selectedProductId: '-1'});
        setSelectedProductId('-1');
      }
    }
  }

  const [selectedProductValue, selectedProductLoading, selectedProductError] = useCollection(
    firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('products').doc(selectedProductId)
  );
  if (selectedProductError) console.error(selectedProductError);

  let selectedProductData = {};
  if (!selectedProductLoading && selectedProductValue) {
    selectedProductData = selectedProductValue.data() || {};
  }

  const emitSelectedProduct = () => {
    console.log('-------------- emitSelectedProduct called')
    if (!selectedProductLoading && selectedProductValue) {
      console.log('-------------- emitSelectedProduct called ... ')
      // if (tasksValue) {
      //   _.each(tasksValue.docs, (ele) => {
      //     console.log('PREPRE');
      //     console.log(ele.data());
      //   })
      // }
      console.log("------- socket setSelectedProduct");
      console.log(selectedProductData);
      let selectedProductCopy = _.cloneDeep(selectedProductData);

      console.log(selectedProductCopy);

      selectedProductCopy.productId = selectedProductId
      if (!selectedProductId) {
        console.warn("WARN: selectedProductId: " + selectedProductId)
      }
      console.log(selectedProductCopy);

      let urlPromises = [];
      _.each(selectedProductCopy.regions, (region, regionIdx) => {
        console.log("-------- region ")
        // console.log('region: ' + rIdx);
        // console.log(region);
        _.each(region.tasks, (taskPath, taskId) => {
          console.log("-------- task ")
          // console.log('task: ' + taskId);
          // console.log(taskPath);
          selectedProductCopy.regions[regionIdx].tasks[taskId] = {aiSettings: {}};

          let foundTask = _.find(tasksValue.docs, (o) => {
            return o.id === taskId;
          });

          if (foundTask) {
            // console.log('foundTask');
            // console.log(foundTask);

            let taskData = foundTask.data();
            if (taskData.aiSettings) { // we copy aiSettings here to provide these even in case the downloadUrl resolvement fails.
              selectedProductCopy.regions[regionIdx].tasks[taskId].aiSettings = taskData.aiSettings;
            }

            if (_.get(taskData, 'aiSettings.aiStoragePath')) {
              // console.log("PATH PATH")
              // console.log(_.get(taskData, 'aiSettings.aiStoragePath'))
              urlPromises.push(
                new Promise((resolve, reject) => {
                    firebase.storage().ref(_.get(taskData, 'aiSettings.aiStoragePath')).getDownloadURL()
                    .then((aiDownloadUrl) => {
                      console.log("!!!!!!!! got download url")
                      resolve({
                          aiStoragePath: _.get(taskData, 'aiSettings.aiStoragePath'),
                          aiDownloadUrl: aiDownloadUrl,
                          taskId: taskId,
                          regionIdx: regionIdx,
                        });
                    })
                    .catch((error) => {
                      console.warn("Promise error...")
                      console.warn(error);
                      console.log("!!!!!!!!!!!!! promise error...")
                      reject(error);
                    })
                }).catch((error) => {
                  console.log("!!!!!!!!!!!!! promise error22...")
                })
              );
            }
          }

        });
      });
      // emitting direclly the data we have. this will not include downloadURL
      socket.emit('setSelectedProduct', selectedProductCopy);

      Promise.all(urlPromises)
      .then((result) => {
        console.log('promise all...');
        console.log(result);
        _.each(result, (res) => {
          selectedProductCopy.regions[res.regionIdx].tasks[res.taskId].aiSettings.aiDownloadUrl = res.aiDownloadUrl;
        });
        console.log(selectedProductCopy);
        console.warn(selectedProductCopy.productId);
        // emitting again including downloadURL etc.
        socket.emit('productData', selectedProductCopy);
      })
      .catch((error) => {
        console.error('Could not get aiDownloadUrl');
        console.error(error);
      });
    }
  }

  useDeepCompareEffect (()=> {
    console.log("socket setSelectedProduct");
    emitSelectedProduct();
  // eslint-disable-next-line
  }, [selectedProductId, selectedProductData, selectedProductLoading, docArrayToData(_.get(tasksValue, 'docs'))]); // tasksValue is a list of docs..

  // getSelectedProduct
  useDeepCompareEffect (() => {
    const listener = (payload) => {
      console.log("on getSelectedProduct");
      emitSelectedProduct();
    };
    socket.on('getSelectedProduct', listener);
    return  () => {
      console.log('removing getSelectedProduct listener - useEffect CLEANUP');
      socket.off('getSelectedProduct', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
}, [selectedProductId, docToData(selectedProductValue)]); // we rely upon the socket not changing


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PROCESS LOCAL CLASSIFICATION RESULTS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect (() => {

    const listener = (payload) => {
      console.log('got classification');
      // console.dir(payload);

      let tmp = {
        classification: payload,
        data: { imageId: payload.imageId },
        operationType: 'classification'
      }

      updateImageHistory(tmp);
      updateHistoryView(tmp);
    };
    console.log("('----------SETTING UP CLASSIFICATION LISTENER");

    socket.on('classification', listener);
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing and the reducers don't change..



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PROCESS NEW IMAGES
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useDeepCompareEffect (() => {
    console.log("SETTING UP CONTEXT IMAGE HANDLER");
    const listener = (payload) => {
      if (payload.type !== 'scan') {
        console.info('CONTEXT: ignoring image due to type not scan. Type: ' + payload.type)
        return; // do not work on non scan type images.
      }
      console.log('Context got image.. (omitting imageData ->)');
      console.log(_.omit(payload, 'imageData'));

      if (selectedCustomerId && payload.imageId && !selectedProductLoading && !deviceSettingsLoading && selectedProductId !== '-1') { // only upload if we have a selectedCustomerId set
        let regionObj = _.cloneDeep(selectedProductValue.data().regions);
        _.each(regionObj, (r, rIdx) => {
          _.each(r.tasks, (t, tId) => {
            regionObj[rIdx].tasks[tId] = {
              taskId: tId,
              classification: null,
              classificationSource: null,
              classificationTime: null,
              classifications: [],
              markImage: null,
            }
            // merge classification results which already are present.
            _.each(payload.regionTasks, (sourceRegionTask) => {
              if (sourceRegionTask.region.top === r.top &&
                sourceRegionTask.region.left === r.left &&
                sourceRegionTask.region.bottom === r.bottom &&
                sourceRegionTask.region.right === r.right &&
                sourceRegionTask.taskId === tId &&
                sourceRegionTask.classification)
              {

                console.log("GOT CLASSIFICATION WITH IMAGE")

                  regionObj[rIdx].tasks[tId].classification = sourceRegionTask.classification;
                  regionObj[rIdx].tasks[tId].classificationSource = 'Edge AI';
                  regionObj[rIdx].tasks[tId].classificationTime = firebase.firestore.FieldValue.serverTimestamp();
                  regionObj[rIdx].tasks[tId].classifications = [
                    {
                      classification: sourceRegionTask.classification,
                      classificationSource: 'Edge AI',
                      localClassificationTime: new Date(),
                    }
                  ];
              }
            })
          })
        })

        payload.regions  = regionObj;
        payload.shouldUpload = networkSettings.isEnabled;

        updateImageHistory({data: payload, operationType: 'overwrite'});
        console.log("updateImageHistory... done");


        if (networkSettings.isEnabled) { // try to upload if we're supposed to be online..
          // adding task and product image data... we update only metadata first as this is way faster than uploading..
          console.time('Uploading Image and Metadata: ' + payload.imageId);

          let taskImagesAdd = [];
          _.each(selectedProductValue.data().regions, (region) => {
            _.each(region.tasks, (taskPath, taskId) => {
              let dataTmp = {
                customerId: selectedCustomerId,
                productId: selectedProductId,
                taskId: taskId,
                taskPath: taskPath,
                deviceId: deviceId,
                imageId: payload.imageId,
                imageType: payload.imageType,
                type: payload.type,
                isImageUploaded: false,
                imageUrl: null,
                resolution: payload.resolution,
                classification: null,
                classificationSource: null,
                classificationTime: null,
                classificationAssigneeId: null,
                classifications: [],
                region: {
                  top: region.top,
                  left: region.left,
                  bottom: region.bottom,
                  right: region.right,
                },
                _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              }

              // bit nasty as we did it already before ... TODO: dont loop here and before..
              _.each(regionObj, (r, rIdx) => {
                _.each(r.tasks, (t, tId) => {
                  // merge classification results which already are present.
                  _.each(payload.regionTasks, (sourceRegionTask) => {
                    if (sourceRegionTask.region.top === region.top &&
                      sourceRegionTask.region.left === region.left &&
                      sourceRegionTask.region.bottom === region.bottom &&
                      sourceRegionTask.region.right === region.right &&
                      sourceRegionTask.taskId === taskId &&
                      sourceRegionTask.classification)
                    {
                      console.log("GOT CLASSIFICATION WITH IMAGE");
                      dataTmp.classification = sourceRegionTask.classification;
                      dataTmp.classificationSource = 'Edge AI';
                      dataTmp.classificationTime = firebase.firestore.FieldValue.serverTimestamp();
                      dataTmp.classifications = [
                                    {
                                      classification: sourceRegionTask.classification,
                                      classificationSource: 'Edge AI',
                                      localClassificationTime: new Date(),
                                    }
                                  ];

                    }
                  })
                })
              })


              taskImagesAdd.push(
                firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('tasks').doc(taskId).collection('taskimages').add(dataTmp)
              );
            });
          });


          let dataTmp = {
            deviceId: deviceId,
            imageId: payload.imageId,
            imageType: payload.imageType,
            type: payload.type,
            isImageUploaded: false,
            imageUrl: null,
            resolution: payload.resolution,
            classification: null, // tricky, has to be calculated somehow out of the tasks
            classificationSource: null, // tricky, has to be calculated somehow out of the tasks
            classificationTime: null, // tricky, has to be calculated somehow out of the tasks
            _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          };


          let productImageAdd = firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('products').doc(selectedProduct.id).collection('productimages').add(dataTmp)

          let storageRef = firebase.storage().ref('customerdata/' + selectedCustomerId + '/productimages/' + selectedProduct.id + '/' + payload.imageId);

          let storageUpload = storageRef.putString(`data:image/${payload.imageType};base64,${payload.imageData}`, 'data_url');

          Promise.all(_.concat(taskImagesAdd, storageUpload, productImageAdd)) // NOTE: we rely upon ordering here..
          .then((result) => {

            let productImageDoc = _.last(result);
            let taskImageDocs = _.slice(result, 0, _.size(result) - 2);
            storageRef.getDownloadURL().then(function(downloadURL) {
              console.log('File available at', downloadURL);
              let updateObj = {
                isImageUploaded: true,
                imageUrl: downloadURL,
                imageStoragePath: storageRef.fullPath,
                imageStorageBucket: storageRef.bucket,
              }

              let imageMerge = {
                imageId: payload.imageId,
                isImageUploaded: true,
                imageUrl: downloadURL,
                imageStoragePath: storageRef.fullPath,
                imageStorageBucket: storageRef.bucket,
              }
              updateImageHistory({data: imageMerge, operationType: 'merge'});
              updateHistoryView({data: imageMerge, operationType: 'merge'});


              let taskUpdates = _.map(taskImageDocs, (doc) => {
                return doc.update({...updateObj, productImageRef: productImageDoc});
              });
              let productUpdate = productImageDoc.update({...updateObj, tasks: taskImageDocs});

              Promise.all(_.concat(taskUpdates, productUpdate))
              .then((results) => {
                console.timeEnd('Uploading Image and Metadata: ' + payload.imageId);
              });
            });
          })
          .catch((err) => {
            console.error('taskImageAdd Error');
            console.error(err);
          });
        } else {
          console.warn('networkSettings.isEnabled not true: NETWORK INFO')
          console.warn(networkSettings);
        }
      } else {
        console.warn('Image not processed - we are not ready... selectedProductLoading: ' + selectedProductLoading + ' selectedCustomerId: ' + selectedCustomerId + ' deviceSettingsLoading: ' + deviceSettingsLoading  );
      }
    };
    console.log("('----------SETTING UP IMAGE LISTENER networkSettings.isEnabled: " + networkSettings.isEnabled);
    socket.on('image', listener);

    return  () => {
      console.log('----------removing image listener - useEffect CLEANUP');
      socket.off('image', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
  }, [networkSettings.isEnabled, selectedCustomerId, selectedProductData, selectedProductLoading, deviceSettingsLoading, deviceSettingsData, selectedProductId]); // we rely upon the socket not changing



  // NOTE: maybe we need to do it as well for historyView - only reason could be a race condition though when removing and entering viewing..
  ///////////////////////////////////////////////////////////////////////////////////////////
  // SETUP OUR TASK LISTENERS
  ///////////////////////////////////////////////////////////////////////////////////////////


  let selectedProductTasks = {};
  if (!selectedProductLoading && selectedProductValue && selectedCustomerId && selectedProductValue.data()) {
    _.each(selectedProductValue.data().regions, (region) => {
      _.each(region.tasks, (value, key) => {
        selectedProductTasks[key] = value;
      })
    });
  }

  useEffect ( () => {
    console.log("TASK LISTENED NEW !!!!!!!!!!!!");
    let taskListeners = [];
    if (!selectedProductLoading && selectedProductValue && selectedCustomerId) {
      // console.dir(selectedProductData);
      //
      // console.log("TASKS IN USE: ")
      // console.dir(selectedProductTasks);

      _.each(selectedProductTasks, (taskPath, taskId) => {
        // TODO: 100 is a dummy limit for now. eventually we should calculate the real max based upon our selected product and its task usage...
        taskListeners.push(firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('tasks').doc(taskId).collection('taskimages').where('deviceId', '==', deviceId).orderBy('_createdAt', 'desc').limit( 10)
          .onSnapshot(querySnapshot => {
            updateImageHistory({operationType:'taskImageUpdate', data: querySnapshot.docs});
            updateHistoryView({operationType:'taskImageUpdate', data: querySnapshot.docs});
          })
        );
      })
    }
    return () => {
      // cleaning up
      console.log('CLEANUP TASK LISTENERS');
      _.each(taskListeners, (taskListener) => {
        taskListener();
      })
    }
  // eslint-disable-next-line
  }, [deviceId, selectedCustomerId, selectedProductLoading, _.join(_.keys(selectedProductTasks))]);
  // use the taskId's (key) as filter.., use deviceId as well



  // useEffect( () => {
  //
  //
  //   return cleanListener;
  // },
  //   _.assign( // the list needs to be of fixed size. hence the filling here.. ordering
  //     _.fill(new Array(HISTORY_LENGTH * 2), null),  // HISTORY_LENGTH * 2 should be the maxium size..
  //     _.union(
  //       _.map(imageHistory, (e) => e.imageId),
  //       _.map(historyView, (e) => e.imageId)
  //     )
  //   )
  //   ); // we use all currently active imageId's as input value..

  ///////////////////////
  // LOAD IMAGES FROM FIREBASE
  // useEffect(()=>{
  //   let observer = firebase.firestore().collection('customerdata').doc(selectedCustomerId).collection('products').doc(taskId).collection('productimages').where('deviceId', '==', deviceId)
  //   .orderBy('_createdAt', 'desc')
  //   .limit(HISTORY_LENGTH)
  //   .onSnapshot(querySnapshot => {
  //     _.each(querySnapshot.docs, (doc) => {
  //       let idx = _.find()
  //
  //       // console.log('received SNAPSHOT update...')
  //     });
  //   });
  //   return (()=>{observer();}); // cleanup
  // },[deviceId]);




    // getDeviceSettings
    useDeepCompareEffect (() => {
      console.log("Getting networkSettings");
      const listener = (payload) => {
        if (deviceSettingsValue) {
          console.log('got getDeviceSettings, emitting setDeviceSettings');
          socket.emit('setDeviceSettings', deviceSettingsValue.data()); // this will be triggered multiple times, as the _updatedAt is a server timestamp. we could use _hasPendingWrites to only go for fully commited ones, but probably not neccessary
        } else {
          console.warn('got getDeviceSettings, NOT emitting setDeviceSettings - deviceSettingsValue not set');
        }
      };
      socket.on('getDeviceSettings', listener);

      return  () => {
        console.log('removing getDeviceSettings listener - useEffect CLEANUP');
        socket.off('getDeviceSettings', listener); // unsubcribe the event listener
      };
    // eslint-disable-next-line
  }, [docToData(deviceSettingsValue)]); // we rely upon the socket not changing



  let deviceSettings = deviceSettingsValue;
  let selectedProduct = selectedProductValue;
  let taskDocs = tasksLoading ? {} : tasksValue;

  // Make the context object:
  const contextObject = {
    socket,
    customerIdList,

    selectedCustomerId,
    setSelectedCustomerId,

    deviceId,
    setDeviceId,

    networkSettings,
    setNetworkSettings,

    deviceSettings,
    setDeviceSettings,
    selectedProduct,

    taskDocs,

    imageHistory,

    historyView,
    updateHistoryView,
    // addNewUser
    isDrawerOpen,
    setIsDrawerOpen,

    fpsObject,

    isUpdateAvailable: props.isUpdateAvailable,
    checkUpdate: props.checkUpdate,
  };

  // pass the value in provider and return
  return <Context.Provider value={contextObject}>{props.children}</Context.Provider>;
};

export const { Consumer } = Context;
