import React, {useContext, useState, useEffect} from 'react';


import { Context } from '../Context';
import InspectionImage from './InspectionImage';

import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';



function Inspection () {
  const context = useContext(Context);
  const { t } = useTranslation();
  const [classificationCombination, setClassificationCombination] = useState(null);

  let styleTmp = {
    width: context.isDrawerOpen ? 'calc(100vw - 288px)' : 'calc(100vw - 118px)',
    height: 'calc(100vh - 48px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  ///////////////////////////////////
  // NOTE: for some reasons chromium (mostly in kiosk mode) doesnt always update the displayd content. we hence schedule further updates here to make sure things are visible..
  let tmpClassificationCombination = "";
  if (context.imageHistory[0]) {
    _.each(context.imageHistory[0].regions, (r, rIdx) => {
      _.each(r.tasks, (t) => {
        tmpClassificationCombination = tmpClassificationCombination + '|' + t.classification;
      })
    });
  }

  useEffect (() => {
    let timeoutId = null;
    console.log('classificationCombination', classificationCombination);
    console.log('tmpClassificationCombination', tmpClassificationCombination);
    if (classificationCombination !== tmpClassificationCombination) {
      console.log('INSPECTION UPDATING STATE...');
      // setClassificationCombination(tmpClassificationCombination);
      timeoutId = setTimeout (() => {
        console.log('INSPECTION TIMEOUT')
        setClassificationCombination(tmpClassificationCombination)}
        , 50);
    }
    return () => {
      if (timeoutId !== null) {
        console.log('INSPECTION clearing timeout');
        clearTimeout(timeoutId);
      }
    }
  });


console.log('INSPECTION RENDER');

console.log("INSPECTION classificationCombination: ", classificationCombination);
  return (
          <React.Fragment>
          <div>
          </div>
            { _.isUndefined(context.imageHistory[0]) &&
              <div style={styleTmp}>
                <Typography>{t('Waiting for image')}</Typography>
              </div>
            }
            { !_.isUndefined(context.imageHistory[0]) &&
              <div style={styleTmp}>
                <InspectionImage fps={_.get(context, 'fpsObject.fps')} image={context.imageHistory[0]} refreshToken={new Date()} classificationCombination={classificationCombination} /> {//refreshToken={refreshToken}/>
                }
              </div>
            }

          </React.Fragment>
        );

}

export default Inspection;
