export default {
  "translation": {
    "Inspection": "Inspektion",
    "History": "Historie",
    "Settings": "Einstellungen",
    "IP": "IP",
    "Mac": "Mac",
    "Mode": "Mode",
    "I/O Configuration": "I/O Konfiguration",
    "Exposure": "Belichtung",
    "Edit": "Ändern",
    "Device Offline": "Gerät offline",
    "Online": "Online",
    "Delete Product?": "Produkt löschen?",
    "Delete Region?": "Region löschen?",
    'You are about to delete the product. This will not delete Tasks. This action cannot be undone': 'Soll das Produkt "{{productname}}" gelöscht werden? Tasks werden nicht gelöscht. Das Löschen lässt sich nicht rückgängig machen!',
    '<product name>': '<Produktname>',
    '<task name>': '<Taskname>',
    'Loading Device settings...': 'Lade Gerät-Einstellungen...',
    'Loading User...': 'Lade Nutzer...',
    'Take Image': 'Bild aufnehmen',
    'Manual Mode': 'Manueller Modus',
    'Offline Mode': 'Offline Modus',
    'Go Online to access settings': 'Für weitere Einstellungen bitte den Offline Modus deaktivieren',
    'Delete Task?': 'Task löschen?',
    'You are about to delete the task "{{taskname}}". This will delete the Tasks and removes it from all Products. This action cannot be undone': 'Soll der Task "{{taskname}}" gelöscht werden? Dies wird den Task löschen und aus allen Produkten entfernen. Das Löschen lässt sich nicht rückgängig machen!',
    'New Task': 'Task anlegen',
    'Add Region': 'Region hinzufügen',
    'Delete Region': 'Region löschen',
    'Delete Task': 'Task löschen',
    'Delete Product': 'Produkt löschen',
    'You are about to delete the current region. This will delete the Region. Tasks or Products will not be deleted. This action cannot be undone': 'Soll die aktuelle Region gelöscht werden? Dies wird die Region löschen, Tasks und Produkte werden nicht gelöscht. Das Löschen lässt sich nicht rückgängig machen!',
    'input': 'Eingang',
    'High': 'High',
    'Low': 'Low',
    'Last Region': 'Vorherige Region',
    'Next Region': 'Nächste Region',
    'accepted': 'Akzeptiert',
    'rejected': 'Abgelehnt',
    'unclassified': 'Unbekannt',
    'accept': 'akzeptieren',
    'reject': 'ablehnen',
    'mark': 'markieren',
    'No task selected. Please select at least one.': 'Kein Task selektiert. Bitte mindestens einen selektieren.',
    'clear marking': 'Markierung löschen',
    'Delete': 'Löschen',
    'Save':'Speichern',
    'Cancel': 'Abbrechen',
    'Save Productimage': 'Produktbild speichern',
    'All image triggers are disabled while liveview is active!': 'Kein Inspektionsbetrieb während Livebild!',
    'done': 'fertig',
    'malfunction': 'Störung',
    'not used': 'nicht benutzt',
    'Pin': 'Pin',
    'Add new Product': 'Neues Produkt',
    'Network': 'Netzwerk',
    'Language': 'Sprache',
    'Raising Edge': 'Steigende Flanke',
    'Falling Edge': 'Fallende Flanke',
    'Manual Trigger': 'Manueller Trigger',
    'Images history is empty': 'Bild-Historie ist leer',
    'No image': 'Kein Bild',
    'Waiting for image': 'Warte auf Bild',
    'Update available': 'Update verfügbar',
    'Update': 'Updaten',
    'check for updates': 'updates suchen',
    'no task': 'kein Task',
    'Test output pins': 'Ausgänge testen',
    'Cloud AI Offline': 'Cloud AI offline',
    'Device': 'Gerät',
    'Name': 'Name',
    'Wrong Access Code': 'Falscher Zugangscode',
    'Access Code': 'Zugangscode',
    'Unlock': 'Freischalten',
    'Sign In': 'Anmelden',
    'Password': 'Passwort',
    'keyboard_bksp': '⌫',
    'keyboard_enter': '↵',
    'keyboard_shift': '⇧',
    'keyboard_tab': '⇥',
    'keyboard_lock': '⇪',
    'keyboard_accept': 'Senden',
    'keyboard_space': ' ',
    'keyboard_//': ' ',
    'Signing In...': 'Anmelden...',
    'Gixel is starting up...': 'Gixel startet...',
    'keyboard_layout_default': [
      "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {bksp}",
      "@ q w e r t z u i o p ü +",
      "{lock} a s d f g h j k l ö ä #",
      "{shift} < y x c v b n m , . - {shift}",
      "{space}"
    ],
    'keyboard_layout_defaukt_shift': [
      "! \" § $ % & / ( ) = ? ` {bksp}",
      "@ Q W E R T Y U I O P Ü *",
      "{lock} A S D F G H J K L Ö Ä '",
      "{shift} > Z X C V B N M ; : _ {shift}",
      "{space}"
    ],
    'keyboard_layout_enter': [
      "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {bksp}",
      "@ q w e r t z u i o p ü + {enter}",
      "{lock} a s d f g h j k l ö ä # {enter}",
      "{shift} < y x c v b n m , . - {shift}",
      "{space}"
    ],
    'keyboard_layout_enter_shift': [
      "! \" § $ % & / ( ) = ? ` {bksp}",
      "@ Q W E R T Y U I O P Ü * {enter}",
      "{lock} A S D F G H J K L Ö Ä ' {enter}",
      "{shift} > Z X C V B N M ; : _ {shift}",
      "{space}"
    ],
    'keyboard_layout_ip': [
      "1 2 3 4 5",
      "6 7 8 9 0",
      ". {bksp}",
    ],
    'Preprocessing': 'Pre',
    'Preprocessing Settings for {{deviceName}}': 'Preprocessing Einstellungen für {{deviceName}}',
    'Edits are immediately saved': 'Änderungen werden sofort gespeichert',
    'Close': 'Schliessen',

  }
}
