import React, {useContext, useState, useEffect} from 'react';

import Slider from '@material-ui/core/Slider';

import { Context } from '../Context';
import Liveview from './Liveview';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  liveview: {
    // height: '427px',
    height: 'calc(100vh - 210px)',
    textAlign: 'center',
  },
  slider: {
    paddingTop: theme.spacing(8),
    height: '100px',
  },
}));

function ExposureSlider(props) {
  const context = useContext(Context);
  const [exposure, setExposure] = useState(props.exposure);
  const [oldExposure] = useState(props.exposure);


  const handleSliderChange = (event, newValue) => {
    if (newValue !== exposure) {
      setExposure(newValue);
      console.log('slider change... ' + newValue); // NOTE: useEffect will emit the next value..
    }
  };

  useEffect ( () => {
    console.log('emiting setExposure: ' + exposure);
    context.socket.emit('setExposure', {exposure: exposure});
  // eslint-disable-next-line
  }, [exposure]); // we do not use  socket here..

  const handleSliderChangeComitted = (event, newValue) => {

    console.log('slider finished change... ' + newValue);
    if (props.handleExposureSave) {
      console.log('updating handleExposureSave');
      props.handleExposureSave(newValue);
    }
    // context.setDeviceSettings({exposure: newValue});
  };

  return  <Slider
            value={typeof exposure === 'number' ? exposure : 5}
            valueLabelDisplay="auto"
            // marks
            step={0.1}
            // min={0.5}
            // max={30}
            marks = {[{
              value: oldExposure,
              label: oldExposure,
            }]}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeComitted}
            aria-labelledby="input-slider"
          />;
}

function Exposure(props) {
  const classes = useStyles();

  return  (
      <React.Fragment>
        <div className={classes.liveview}>
          <Liveview />
        </div>
        <div className={classes.slider}>
          <ExposureSlider handleExposureSave={props.handleExposureSave} exposure={props.exposure}/>
        </div>
        </React.Fragment>
      );
}

export default Exposure;
