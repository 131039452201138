
import React, {useContext, useEffect, useState, useCallback} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';


import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';

import { Stage, Layer, Image as KonvaImage, Rect, Group } from 'react-konva';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FullscreenDialog from './base/FullscreenDialog';
import EditDialog from './base/EditDialog';

import Exposure from './Exposure';
import Liveview from './Liveview';

import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteIcon from '@material-ui/icons/Delete';

import SnapshotIcon from '@material-ui/icons/PhotoCamera';
import ExposureIcon from '@material-ui/icons/Exposure';
import AddRegionIcon from '@material-ui/icons/Add';

import WarningIcon from '@material-ui/icons/Warning';

import EditIcon from '@material-ui/icons/Edit';

import AiEdgeIcon from '@material-ui/icons/Done';
import AiCloudIcon from '@material-ui/icons/Cloud';


import Stepper from './base/Stepper';
import { Context } from '../Context';
import useImage from './base/useImage';


// import useImage from './base/useImage';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  regionContainer: {
    display: 'grid',
    gridTemplateColumns: '[start] min-content [slider] 50px [end]',
    gridTemplateRows: '[start] min-content [slider] 50px [end]',
  },

  regionImage: {
    gridColumnStart: 'start',
    gridColumnEnd: 'span slider',
    gridRowStart: 'start',
    gridRowEnd: 'span slider',
  },
  regionSliderHorizontal: {
    gridColumnStart: 'start',
    gridColumnEnd: 'span slider',
    gridRowStart: 'slider',
    gridRowEnd: 'span end',
    alignSelf: 'end',
  },
  regionSliderVertical: {
    gridColumnStart: 'slider',
    gridColumnEnd: 'span end',
    gridRowStart: 'start',
    gridRowEnd: 'span slider',
    justifySelf: 'end',
  },
  centerVertHort: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snapshotButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  liveview: {
    // height: '427px',
    height: 'calc(100vh - 120px)',
    textAlign: 'center',
  },
  stageContainer: {
    maxHeight: 'calc(100vh - 250px)',
    maxWidth: 'calc(70vw - 50px)',
    display: 'flex',
    justifyContent: 'center', //flex-end
    alignItems: 'center', //flex-end

    //maxHeight: 'calc(100vh - 250px)', maxWidth: 'calc(70vw - 50px', objectFit: 'contain'
},
}));


function ProductEditRegion(props) {
  const context = useContext(Context);
  const classes = useStyles();

  const [size, setSize] = useState({width: 640, height: 480, scale: {x: 1, y: 1}});
  const [resizeCounter, setResizeCounter] = useState(0);

  let regions = props.productDoc.data().regions;
  let region = _.clone(regions[props.editRegionIdx]);

  // convert region coordinate y..
  let resolution = {width: 640, height: 480};
  if (props.productDoc && _.has(props.productDoc.data(), 'image.resolution')) {
    resolution = _.get(props.productDoc.data(), 'image.resolution');
  }

  region.top = resolution.height - region.top;
  region.bottom = resolution.height - region.bottom;

  const [regionX, setRegionX] = React.useState([region.left, region.right]);
  const [regionY, setRegionY] = React.useState([region.bottom, region.top]); // the slider points are 1. down, 2. up

  useEffect(() => { // update our local region values in case we get an update.. - only called if left/right/top or bottom changed
    setRegionX([region.left, region.right]);
    setRegionY([region.bottom, region.top]); // the slider points are 1. down, 2. up
  }, [region.left, region.right, region.top, region.bottom]);


  const handleChangeRegionX = (e, newValue) => {
    setRegionX(newValue);
  }
  const handleChangeRegionY = (e, newValue) => {
    console.log("handleChangeRegionY");
    setRegionY(newValue);
  }

  const handleChangeRegionXCommited = (e, newValue) => {
    let regioncopy = _.cloneDeep(regions);
    regioncopy[props.editRegionIdx].left = newValue[0];
    regioncopy[props.editRegionIdx].right = newValue[1];
    props.productDoc.ref.update({
      regions: regioncopy
    })
    .then(function() {
      console.log("updated region..");
    });
  };

  const handleChangeRegionYCommited = (e, newValue) => {
    let regioncopy = _.cloneDeep(regions);
    regioncopy[props.editRegionIdx].bottom = resolution.height - newValue[0];
    regioncopy[props.editRegionIdx].top = resolution.height - newValue[1];
    props.productDoc.ref.update({
      regions: regioncopy
    })
    .then(function() {
      console.log("updated region..");
    });
  };


  // we use the effect to get our resizerCallback called again..
  useEffect(() => {
    function resizer () {
      setResizeCounter(c => c + 1);
    }
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  }, []);

  const resizerCallback = useCallback((node) => {
    if (node !== null) {
      let scale = Math.min(
                            node.offsetWidth / resolution.width,
                            node.offsetHeight / resolution.height,
                          );
      setSize({width: resolution.width*scale, height:resolution.height*scale, scale: {x: scale, y:scale}})
    }
  //eslint-disable-next-line
}, [resizeCounter, resolution.width, resolution.height]); // NOTE: we use the resizecounter here to recalculate whenever a resize event happens..

//
//
// <React.Fragment>
//   <img alt='productimage' src={props.productDoc.data().image.imageUrl} style={{maxHeight: 'calc(100vh - 250px)', maxWidth: 'calc(70vw - 50px', objectFit: 'contain'}} ref={resizerCallback}/>
//   <img alt='productimage' src={props.productDoc.data().image.imageUrl}
//     style={
//       {
//         position: 'absolute',
//         top: '0px',
//         left: '0px',
//         maxHeight: 'calc(100vh - 250px)',
//         maxWidth: 'calc(70vw - 50px',
//         objectFit: 'contain',
//         filter: 'brightness(0.5)',
//         clipPath: `polygon(
//           /* points of the outer rect (full image) going counterclockwise */
//           0px 0px, ${resolution.width * scale.x}px 0px, ${resolution.width * scale.x}px ${resolution.height * scale.y}px, 0px ${resolution.height * scale.y}px, 0px 0px,
//
//           /* return to the first point of the outter rect */
//           ${regionX[0] * scale.x}px ${resolution.height * scale.y - regionY[1] * scale.y  }px,
//
//           /* points of the inner rect going clockwise */
//           ${regionX[0] * scale.x}px ${resolution.height * scale.y - regionY[0] * scale.y  }px, ${regionX[1] * scale.x}px ${resolution.height * scale.y - regionY[0] * scale.y }px, ${regionX[1] * scale.x}px ${resolution.height * scale.y - regionY[1] * scale.y }px, ${regionX[0] * scale.x}px ${resolution.height * scale.y - regionY[1] * scale.y }px,
//
//           /* return to the first point of the outter rect */
//           0px 0px
//         )`,
//       }
//     }/>
// </React.Fragment>
  const [productImage] = useImage((props.productDoc && _.has(props.productDoc.data(), 'image.imageUrl')) ? props.productDoc.data().image.imageUrl : "");

// We're using a regular image with opcatity 0 to get the sizing.
// NOTE: if the resolution of the images is not correctly set we will get scaling differences between the canvas image and the other image. As the canvas is using the input resolution and the <img> the resolution from the downloaded image...
  return (
    <React.Fragment>
      <div className={classes.regionContainer}>
        <Slider
          value={regionX}
          onChange={handleChangeRegionX}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          onChangeCommitted={handleChangeRegionXCommited}
          min={0}
          max={props.productDoc.data().image.resolution.width}
          className={classes.regionSliderHorizontal}
        />
        <Slider
          orientation="vertical"
          value={regionY}
          onChange={handleChangeRegionY}
          onChangeCommitted={handleChangeRegionYCommited}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(x) => {
            return resolution.height - x;
          }}
          min={0}
          max={props.productDoc.data().image.resolution.height}
          className={classes.regionSliderVertical}
        />
        <div className={classes.regionImage} >
          <div style={{position:'relative'}} >
              {(props.productDoc && _.has(props.productDoc.data(), 'image.imageUrl')) &&
              <React.Fragment>
              <div style={{position: 'relative'}}>
                <img alt='productimage' src={props.productDoc.data().image.imageUrl} style={{position: 'relative', maxHeight: 'calc(100vh - 250px)', maxWidth: 'calc(70vw - 50px', objectFit: 'contain', opacity: 0.0}} ref={resizerCallback}/>
                <Stage
                  style={{position:'absolute', top: '0px', left:'0px'}}
                  width={size.width}
                  height={size.height}
                  scale={size.scale}
                >
                  <Layer>
                    <KonvaImage
                      image={productImage}
                      width={resolution.width}
                      height={resolution.height}
                    />
                    <Rect
                      x={0}
                      y={0}
                      width={resolution.width}
                      height={resolution.height}
                      fill={'black'}
                      opacity={0.65}
                      />
                      <Group
                        clip = {{ x: regionX[0], y: (resolution.height- regionY[1]), width: regionX[1] - regionX[0], height: (resolution.height - regionY[0]) - (resolution.height - regionY[1])}}
                      >
                      <KonvaImage
                        image={productImage}
                        width={resolution.width}
                        height={resolution.height}
                      />
                      </Group>
                  </Layer>
                </Stage>
                </div>
                </React.Fragment>
              }
          </div>
        </div>
      </div>
    </React.Fragment>
    );

}


function RegionSelector(props) {
  function handleNext() {
    props.setEditRegionIdx(Math.min(props.editRegionCount - 1, props.editRegionIdx + 1));
  }

  function handleBack() {
    props.setEditRegionIdx(Math.max(0, props.editRegionIdx - 1));
  }

  // no need for the region selector if we have less than 2 regions
  if (props.regionCount < 2) {
    return null
  }

  console.log('current:' + props.editRegionIdx + ' size:' + props.editRegionCount);

  return (
    <Stepper regionCount={props.editRegionCount} activeRegionIdx={props.editRegionIdx} handleNext={handleNext} handleBack={handleBack} />
  );

}

function TaskSelector (props) {
  const context = useContext(Context);
  const { t } = useTranslation();
  const [taskDeleteDoc, setTaskDeleteDoc] = useState(null);
  const [editTask, setEditTask] = useState(null);

  const [tasksValue, tasksLoading, tasksError] = useCollection(
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('tasks')
    .where('_isDeleted', '==', false)
    .orderBy('name').orderBy('_createdAt', 'asc')
  );
  if (tasksError) console.error(tasksError);

  function handleSelectTask(value, taskDoc) {
    let tasks = props.productDoc.data().regions[props.editRegionIdx].tasks || {};
    if (value) {
      tasks[taskDoc.id] = taskDoc.ref.path; // using only ref would be nicer, but we had some trouble emitting a 'reference' datatype
      // tasks[taskDoc.id] = taskDoc.ref; // using only ref would be nicer, but we had some trouble emitting a 'reference' datatype
    } else {
      tasks = _.omit(tasks, taskDoc.id);
    }
    let regions = props.productDoc.data().regions;

    regions[props.editRegionIdx].tasks = tasks;

    props.productDoc.ref.update({
      regions: regions,
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }).then(function() {
      console.log("updated task selection..");
    });
  }

  function handleTaskDeleteDialogConfirm() {
    // remove the task from any products
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('products').get()
    .then(function (querySnapshot) {
        console.log('iterating over products..');
        let promiseArray = [];
        _.each(querySnapshot.docs, (doc) => {
          console.log('products', doc);
          let regions = doc.data().regions;
          let requiresUpdate = false;
          // NOTE: we only check for the task to delete. we do not check if all associated Tasks are correct. maybe something to change somewhen..
          _.each(doc.data().regions, (region, idx) => {
            if (_.has(region.tasks, taskDeleteDoc.id)) {
              console.log('checking for path:', taskDeleteDoc.id, region.tasks);
              regions[idx].tasks = _.omit(region.tasks, taskDeleteDoc.id);
              requiresUpdate = true;
            }
          });
          if (requiresUpdate) {
            promiseArray.push(
              doc.ref.update({
                regions: regions,
                _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              }));
          }
        });
        Promise.all(promiseArray).then(function(){
          taskDeleteDoc.ref.update(
            {
              _isDeleted: true,
              _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
          ).then(function() {
            console.log("Task successfully deleted!");
            setTaskDeleteDoc(null);
          }).catch(function(error) {
            console.error("Error removing task: ", error);
          });
        })
        .catch(function(error) {
          console.error("Error removing task from products: ", error);
        });
     }).catch(function(error) {
         console.error("Error querying products (for task deletion): ", error);
     });
     setEditTask(null);
  }

  function handleTaskEditSave (doc, name) {
    doc.ref.update(
      {
        name: name,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        console.log("name updated..");
      });
      setEditTask(null);
  }

  function handleTaskEditDelete (doc) {
    console.log('handleTaskEditDelete,,,');
    setTaskDeleteDoc(doc);
  }

  function handleTaskEditClose () {
    setEditTask(null);
  }

  console.log("----------------");
  console.log(props.productDoc.data().regions[props.editRegionIdx].tasks);
  console.log(_.size(props.productDoc.data().regions[props.editRegionIdx].tasks));

  return (
    <React.Fragment>
      { taskDeleteDoc !== null &&
        <Dialog
          open={true}
          onClose={() => setTaskDeleteDoc(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('Delete Task?')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('You are about to delete the task "{{taskname}}". This will delete the Tasks and removes it from all Products. This action cannot be undone', {taskname: taskDeleteDoc.data().name})}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTaskDeleteDoc(null)} >
              {t('Cancel')}
            </Button>
            <Button onClick={handleTaskDeleteDialogConfirm} color="secondary" autoFocus>
              {t('Delete Task')}
            </Button>
          </DialogActions>
        </Dialog>
      }
      { editTask !== null &&
        <EditDialog
          deleteText = {t('Delete Task')}
          handleClose = {handleTaskEditClose}
          handleSave = {handleTaskEditSave}
          handleDelete = {handleTaskEditDelete}
          name={editTask.data().name}
          doc={editTask}
          showKeyboard={true}
         />
      }

      <Button variant="contained" color="primary" onClick={()=>props.addTask()}>{t('New Task')}</Button>
      { (!tasksLoading && tasksValue) &&
        <React.Fragment>
          <List>
            {
              _.map(tasksValue.docs, (e) => {
                let data = e.data();
                return (
                  <ListItem key={e.id}
                            dense
                            button
                            onClick={(event) => handleSelectTask(_.has(props.productDoc.data().regions[props.editRegionIdx].tasks, e.id) ? false : true, e)}
                            >
                    <ListItemIcon>
                      <Checkbox
                        color="primary"
                        edge="start"
                        checked={_.has(props.productDoc.data().regions[props.editRegionIdx].tasks, e.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': e.id }}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                    { _.get(data, 'aiSettings.isEdgeMode') ?
                      <AiEdgeIcon /> : <AiCloudIcon />
                    }

                    </ListItemIcon>
                    <ListItemText>
                      {data.name}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments" onClick={()=>setEditTask(e)}>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );

            })
            }
            { _.size(props.productDoc.data().regions[props.editRegionIdx].tasks) < 1  &&
              <ListItem key={'no task'}>
                <ListItemIcon>
                  <WarningIcon color="secondary"/>
                </ListItemIcon>
                <ListItemText color="secondary">
                  <Typography color='secondary'>{t('No task selected. Please select at least one.')}</Typography>
                </ListItemText>
              </ListItem>
            }

          </List>
        </React.Fragment>
      }
    </React.Fragment>

  );
  // <ListItemText id={e.id} primary={data.name} />


}

// Dialog and Container
function Product(props) {
  const { t } = useTranslation();
  const context = useContext(Context);
  const classes = useStyles();


  const [regionDeleteIdx, setRegionDeleteIdx] = React.useState(null);
  // takeSnapshotStatus: null, 'snapshot', 'exposure' 'exposuresnapshot', 'updating'
  const [takeSnapshotStatus, setTakeSnapshotStatus] = React.useState(_.has(props.productDoc.data(), 'image.imageUrl') ? null : 'exposuresnapshot');
  const [editRegionIdx, setEditRegionIdx] = React.useState(_.isUndefined(props.productDoc.data().regions) ? addRegion() : 0);

  const [editName, setEditName] = React.useState(null);



  if (props.editRegionIdx > _.size(props.productDoc.data().regions - 1)) {
    console.error("editRegionIdx (" + props.editRegionIdx +") > regionCount (" + _.size(props.productDoc.data().regions) - 1 + ") - setting into save bounds..");
    setEditRegionIdx(_.size(props.productDoc.data().regions) - 1);
  }
  // adds a new region
  function addRegion() {
    let regions = props.productDoc.data().regions || [];
    let resolution = {width: 640, height: 480};
    if (_.has(props.productDoc.data() , 'image.resolution')) {
      resolution = _.get(props.productDoc.data() , 'image.resolution');
    }
    //add a new region
    console.log("ASDBA")
    console.log(resolution);
    let data = props.productDoc.data();
    regions.push({top:0, left:0, right: resolution.width, bottom: resolution.height, tasks:{}})
    props.productDoc.ref.update({
      regions: regions
    })
    .then(function() {
      console.log("updated added region..");
      setEditRegionIdx(_.size(props.productDoc.data().regions)); // we don't -1 as the size hasn't yet updated (apparently...)
    });
    return _.size(props.productDoc.data().regions); // the idx to the new region (care about 0/1 index)
  }

  function deleteRegion() {
    let regions = props.productDoc.data().regions
    _.pullAt(regions, regionDeleteIdx);
    props.productDoc.ref.update({
      regions: regions,
    })
    .then(function() {
      console.log("deleted region..");
    });
    setEditRegionIdx(Math.max(0, regionDeleteIdx - 1)); // make sure we stay within bounds
    setRegionDeleteIdx(null);
  }

  // take the image and upload it...
  function handleTakeSnapshopt() {
    // liveview gets disabled automatically when the liveview gets removed... happens by updating with setTakeSnapshotStatus
    setTakeSnapshotStatus('updating'); // we could omve this into the primise as well..
    // adding a dedicated disable liveview here..
    context.socket.emit('setLiveView', {status: 'disabled'});

    context.socket.emit('getImage', {'type': 'snapshot'});

    context.socket.once('image', (payload => { // we rely upon the liveview generating image messages..
      console.time('snapshotUpload');
      let storageRef = firebase.storage().ref('customerdata/' + context.selectedCustomerId + '/productpreview/' + context.selectedProduct.id + '/' + payload.imageId);
      storageRef.putString(`data:image/${payload.imageType};base64,${payload.imageData}`, 'data_url')
      .then(function(snapshot) {
        console.log('Uploaded a the preview!');
        console.time('getDownloadURL..');
        storageRef.getDownloadURL().then(function(downloadURL) {
          console.timeEnd('getDownloadURL..');
          console.timeEnd('start..');
          console.log('File available at', downloadURL);
          let updateObj = {
            image: {
              imageId: payload.imageId,
              imageType: payload.imageType,
              imageUrl: downloadURL,
              imageStoragePath: storageRef.fullPath,
              imageStorageBucket: storageRef.bucket,
              resolution: payload.resolution,
            },
            _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          };
          console.log(updateObj);
          props.productDoc.ref.update(updateObj)
          .then(function() {
            console.timeEnd('snapshotUpload');
            console.log("updated image..");
            setTimeout(()=>{
              setTakeSnapshotStatus(null); // we could move this into the primise as well..
            }, 250); // the timeout is a bit strange, but apparently it resolves the issue of the resizer callback not reporting the correct size and/or the image to be loaded correctly..

          });
        });
      });
    }));
  }

  function addTask() {
    let data = {
      name: t('<task name>'),
      _isDeleted: false,
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('tasks').add(data)
      .then((addRef) => {
        console.log('new task...');
        console.log(addRef);
      });
  }

  function handleExposureSave(exosure) {
    props.productDoc.ref.update(
      {
        exposure: exosure,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        console.log("updated exposure..");
      });
  }

  function handleNameEditSave (doc, name) {
    doc.ref.update(
      {
        name: name,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        console.log("name updated..");
      });
      setEditName(null);
  }

  function handleNameEditClose () {
    setEditName(null);
  }
  return  <FullscreenDialog handleClose={props.handleClose} useTransition={false} hideClose={(takeSnapshotStatus !== null && takeSnapshotStatus !== 'updating')}>
            { regionDeleteIdx !== null &&
              <Dialog
                open={true}
                onClose={() => setRegionDeleteIdx(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{t('Delete Region?')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('You are about to delete the current region. This will delete the Region. Tasks or Products will not be deleted. This action cannot be undone')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setRegionDeleteIdx(null)} >
                    {t('Cancel')}
                  </Button>
                  <Button onClick={()=>deleteRegion()} color="secondary" autoFocus>
                    {t('Delete Region')}
                  </Button>
                </DialogActions>
              </Dialog>
            }

            { editName !== null &&
              <EditDialog
                deleteText = {t('Delete Product')}
                handleClose = {handleNameEditClose}
                handleSave = {handleNameEditSave}
                handleDelete = {props.handleProductDelete}
                name={editName.data().name}
                doc={editName}
                showKeyboard={true}
               />
            }


            { takeSnapshotStatus === null &&
              <React.Fragment>
                <DialogTitle>
                  {props.productDoc.data().name}
                  <IconButton className={classes.snapshotButton} edge="end" aria-label="comments" onClick={()=>setEditName(props.productDoc)}>
                    <EditIcon />
                  </IconButton>

                  <IconButton className={classes.snapshotButton} edge="end" aria-label="comments" onClick={()=>setTakeSnapshotStatus('snapshot')}>
                    <SnapshotIcon />
                  </IconButton>

                  <IconButton className={classes.snapshotButton} edge="end" aria-label="comments" onClick={()=>setTakeSnapshotStatus('exposure')}>
                    <ExposureIcon />
                  </IconButton>
                  <Button className={classes.button} variant="contained" color="primary" onClick={addRegion}><AddRegionIcon />{t('Add Region')}</Button>
                  {_.size(props.productDoc.data().regions) > 1 &&
                    <Button className={classes.button} variant="contained"  onClick={()=>setRegionDeleteIdx(editRegionIdx)}><DeleteIcon />{t('Delete Region')}</Button>
                  }
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <ProductEditRegion editRegionIdx={editRegionIdx} productDoc={props.productDoc} />

                    </Grid>
                    <Grid item xs={3}>

                      <TaskSelector productDoc={props.productDoc} editRegionIdx={editRegionIdx} addTask={addTask}/>
                    </Grid>
                  </Grid>
                </DialogContent>
                { _.size(props.productDoc.data().regions) > 1 &&
                  <RegionSelector setEditRegionIdx={setEditRegionIdx} editRegionIdx={editRegionIdx} editRegionCount={_.size(props.productDoc.data().regions)} />
                }
              </React.Fragment>
            }
            { (takeSnapshotStatus === 'snapshot' || takeSnapshotStatus === 'exposure' || takeSnapshotStatus === 'exposuresnapshot' ) &&
              <React.Fragment>
                <DialogContent>
                  <div className={classes.liveview}>
                    { (takeSnapshotStatus === 'exposure' || takeSnapshotStatus === 'exposuresnapshot' ) &&
                      <Exposure handleExposureSave={handleExposureSave} exposure={props.productDoc.data().exposure} />
                    }
                    { takeSnapshotStatus === 'snapshot' &&
                      <Liveview />
                    }
                  </div>
                </DialogContent>
                <DialogActions>
                { (takeSnapshotStatus === 'snapshot' || takeSnapshotStatus === 'exposuresnapshot' ) &&
                  <React.Fragment>
                    { _.has(props.productDoc.data(), 'image.imageUrl') &&
                      <Button className={classes.button} onClick={()=>setTakeSnapshotStatus(null)}>{t('Cancel')}</Button>
                    }
                    <Button className={classes.button} color="primary" onClick={()=>handleTakeSnapshopt()}>{t('Save Productimage')}</Button>
                  </React.Fragment>
                }
                { takeSnapshotStatus === 'exposure' &&
                  <Button className={classes.button} color="primary" onClick={()=>setTakeSnapshotStatus(null)}>{t('done')}</Button>
                }
                </DialogActions>
              </React.Fragment>
            }
            { takeSnapshotStatus === 'updating' &&
              <DialogContent className={classes.centerVertHort}>
                <CircularProgress color="primary" />
              </DialogContent>
            }
          </FullscreenDialog>;
}

export default Product;
