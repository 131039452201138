import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Chip from '@material-ui/core/Chip';


import { Offline, Online } from "react-detect-offline";
import OfflineIcon from '@material-ui/icons/SignalWifiOff';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

import { useTranslation } from 'react-i18next';

import Keyboard from './base/Keyboard';

import { makeStyles } from '@material-ui/core/styles';

import address from '@hapi/address';

import _ from 'lodash';

import { NETWORKSETTINGSINIT } from '../Defines';

import NetworkSettings from './NetworkSettings';

const useStyles = makeStyles(theme => ({
  authContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 230px)',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
  },
  inputContainer: {
    width: '40vw',
  },
  keyboardContainer: {
    position: 'fixed',
    bottom: '0px',
    width: '100vw',
  },
  buttonContainer: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '40vw',
    marginTop: theme.spacing(4),
  },
  buttonSpacing: {
    marginLeft: theme.spacing(2),
  }
}));


export default function PasswordAuth(props) {
  const classes = useStyles();

  const { t } = useTranslation();
  const [email, setEmail] = React.useState(props.email || "");
  const [password, setPassword] = React.useState(props.password || "");

  const [isShowNetworkSettings, setIsShowNetworkSettings] = React.useState(false);

  const [networkSettings, setNetworkSettings] = React.useState(NETWORKSETTINGSINIT);


  const emailInputRef = React.useRef();
  const passwordInputRef = React.useRef();

  const keyboardRef = React.useRef();

  const [currentFocus, setCurrentFocus] = React.useState('email');


  // get our networkSettings
  useEffect (() => {
    console.log("Getting networkSettings (PasswordAuth)");
    const listener = (payload) => {
      console.log('got networkSettings (PasswordAuth)');
      console.log(payload);
      setNetworkSettings(payload);
    };
    props.socket.on('networkSettings', listener);

    props.socket.emit('getNetworkSettings', {});
    return  () => {
      console.log('removing networkSettings listener - useEffect CLEANUP (PasswordAuth)');
      props.socket.off('networkSettings', listener); // unsubcribe the event listener
    };
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing


  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(event.target.value, 'email');
    }
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(event.target.value, 'password');
    }
  }

  const handleKeyPress = (button) => {
    switch(button) {
      case '{enter}':
        if (currentFocus === 'email') {
          if (passwordInputRef && passwordInputRef.current) {
            setCurrentFocus('password');
            passwordInputRef.current.focus();
          }
        }
        if (currentFocus === 'password') {
          if (emailInputRef && emailInputRef.current) {
            setCurrentFocus('email');
            emailInputRef.current.focus();
          }
        }
        break;
      default:
        // do nothing.. we handle changed in the onChange handler
        break;
    }
  }

  const handleBlur = (event) => {
    if (event.relatedTarget !== emailInputRef.current && event.relatedTarget !== passwordInputRef.current && !isShowNetworkSettings) { // keep focus on either input boxes...
      setTimeout(
        () => {
          if (currentFocus === 'password') {
            if (passwordInputRef && passwordInputRef.current) {
              passwordInputRef.current.focus();
            }
          }
          if (currentFocus === 'email') {
            if (emailInputRef && emailInputRef.current) {
              emailInputRef.current.focus();
            }

          }
        }, 50
      )
    }
  }

  const handleFocusEmail = () => {
    setCurrentFocus('email');
  }
  const handleFocusPassword = () => {
    setCurrentFocus('password');
  }

  const handleKeyboardChange = (input) => {
    switch(currentFocus) {
      case 'email':
        setEmail(input);
        break;
      case 'password':
        setPassword(input);
        break;
      default:
        console.error('currentFocus value unexpected: ' + currentFocus)
        break;
    }
  }

  const handleSignIn = () => {
    if (props.onSignIn && email && password) {
        props.onSignIn(email, password);
    } else {
      console.log("!!! NOT SIGNING IN")
    }
  }

  const showNetworkSettings = () => {
    setIsShowNetworkSettings(true);
  }
  const hideNetworkSettings = () => {
    console.log("hiding networksetting")
    setIsShowNetworkSettings(false);
    if (keyboardRef && keyboardRef.current) {
      console.log(" setting keyboard... " + email)
      keyboardRef.current.setInput(email, "email")
      keyboardRef.current.setInput(password, "password")
    } else {
      console.log(" NO KEYBOARD REF")
    }
  }

  return (
    <React.Fragment>
      { isShowNetworkSettings &&
        <NetworkSettings handleClose={hideNetworkSettings} socket={props.socket} networkSettingsInit={networkSettings} />
      }
      <div className={classes.authContainer}>
        <div className={classes.inputContainer}>
          <TextField
            autoFocus
            required
            fullWidth
            value={email}
            onFocus={handleFocusEmail}
            onBlur={handleBlur}
            onChange={handleChangeEmail}
            inputRef={emailInputRef}
            margin="dense"
            id="email"
            label={t('eMail')}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') { // use enter to save
                  if (passwordInputRef && passwordInputRef.current) {
                    passwordInputRef.current.focus();
                  }
                  ev.preventDefault();
                }
              }}
            error= {props.signInState === 'signInErrorUser'}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextField
            required
            fullWidth
            value={password}
            onFocus={handleFocusPassword}
            onBlur={handleBlur}
            onChange={handleChangePassword}
            inputRef={passwordInputRef}
            margin="dense"
            id="password"
            type="password"
            label={t('Password')}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') { // use enter to save
                  if (!address.email.isValid(email) && emailInputRef && emailInputRef.current) {
                    emailInputRef.current.focus();
                  } else {
                    handleSignIn();
                  }
                  ev.preventDefault();
                }
              }}
            error= {props.signInState === 'signInErrorPassword'}
            helperText={props.signInState === 'signInError' ? t('Something went wrong...') : ''}
          />
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonSpacing}>
            <Button
              color="primary"
              startIcon={<SettingsEthernetIcon color="primary"/>}
              onClick={showNetworkSettings}
            >
              {t('Network Settings')}
            </Button>
          </div>
          <div className={classes.buttonSpacing}>
            <Offline>
              <Button
                className={classes.buttonSpacing}
                variant="contained"
                disabled
                startIcon={<OfflineIcon />}
                onClick={showNetworkSettings}
              >
              {t('Device Offline')}
              </Button>
            </Offline>
            <Online>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignIn}
              >
                {t('Sign In')}
              </Button>
            </Online>
          </div>
        </div>
      </div>
      <div className={classes.keyboardContainer}>
      { !isShowNetworkSettings &&
        <Keyboard
          keyboardRef={(ref) => {
            keyboardRef.current = ref;
              if (ref) {
                ref.setInput(email, "email");
                ref.setInput(password, "password");
              }
            }
          }
          inputName={currentFocus}
          onChange={handleKeyboardChange}
          onKeyPress={handleKeyPress}
          layout={{
            default: t('keyboard_layout_enter', {returnObjects: true}),
            shift: t('keyboard_layout_enter_shift', {returnObjects: true}),
          }}
          />
      }
      </div>
      </React.Fragment>
  );
}
