import React from 'react';

import * as Sentry from '@sentry/browser';

import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';


// --------------------------------------------------------------------------
console.log("LOCAL STORAGE TEST START. VaLUES:")
for(var i in localStorage)
{
    console.log(i);
    console.log(localStorage[i]);
    console.log('--')
}
console.log("LOCAL STORAGE TEST END")

var db;
//check for support
 if (!('indexedDB' in window)) {
   console.log('This browser doesn\'t support IndexedDB');
 } else {
   var request  = window.indexedDB.open('firebaseLocalStorageDb', 1);
   request.onerror = function (event) {
     console.log('The database is opened failed');
   };
   request.onsuccess = function (event) {
    db = request.result;
    console.log('DB TEST: The database is opened successfully');
    console.log('DB TEST: db.objectStoreNames:');
    console.log(db.objectStoreNames);
    if (db.objectStoreNames.length > 0) {
      try {
        var transaction = db.transaction(db.objectStoreNames, 'readonly');

        transaction.oncomplete = function(event) {
          console.log('DB TEST completed transaction')
          db.close();
        }
        transaction.onerror  = function(event) {
          console.warn("DB TEST: transaction.error...");
          console.warn(event);
          db.close();
        }
        var objectStore = transaction.objectStore('firebaseLocalStorage')
         objectStore.openCursor().onsuccess = function (event) {
           var cursor = event.target.result;

           if (cursor) {
             console.log("DB TEST: firebase indexedDB saved UID:")
             console.log(cursor.value.value.uid)
             console.log("DB TEST cursor.value.value")
             console.dir(cursor.value.value);
             console.log("DB TEST cursor.value")
             console.dir(cursor.value);
             console.log("DB TEST cursor")
             console.dir(cursor);
             cursor.continue();
          } else {
            console.log('DB TEST: No more data');
            db.close();
          }
        }
      } catch (e) {
        console.warn("DB TEST ERROR: ")
        console.warn(e);
        db.close();
      }
    } else {
      console.warn("DB TEST: no db.objectStoreNames");
      db.close();
    }
    // } catch (e) {
    //   console.warn('DB TEST: firebaseLocalStorage could not be opened');
    //   console.warn (e)
    // }

  };
 }
// --------------------------------------------------------------------------


let registrationStore = null;
let isUpdateAvailable = null;

let onUpdate = (registration) => {
  console.log('onUpdate... index');
  isUpdateAvailable = registration;
  // registration.waiting.postMessage('skipWaiting');
}

let checkUpdate = () => {
  console.log('checkUpdate pre');
  if (registrationStore !== null) {
    console.log('checkUpdate registrationStore found');
    return registrationStore.update()
  }
}

const updater = (up) => {
  onUpdate = up;
  if (isUpdateAvailable !== null) {
    console.log('updated: calling up - isUpdateAvailable is set...');
    up(isUpdateAvailable)
  }
}

let tmpDsn = (window.location.href.indexOf('localhost') > -1) ? null : "https://268ac056414545aba08379da4d12590c@sentry.io/1765944" ;

console.log("DSN:", tmpDsn);
Sentry.init(
    {
      dsn: tmpDsn,
      relase: 'gixel-client@1.0.0',
    }
  );

ReactDOM.render(<App updater={updater} checkUpdate={checkUpdate}/>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
let config = {
  'registrationHandler': (registration) => {
    console.log('registrationHandler... .');
    console.log(registration);
    registrationStore = registration;
  },
  'onUpdate': (registration) => {
    console.log('onUpdate...');
    onUpdate(registration);
  },
  'onSuccess': (registration) => {
    console.log('onSuccess...');
  },
  'onActivated': (registration) => {
    console.log('onActivated...');
    window.location.reload();
  }
}

serviceWorker.register(config);
