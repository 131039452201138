import React, {useContext, useEffect} from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';


import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import PinHighIcon from '@material-ui/icons/Power';
import PinLowIcon from '@material-ui/icons/PowerOff';


import AiEdgeIcon from '@material-ui/icons/Done';
import AiCloudIcon from '@material-ui/icons/Cloud';

// import { Online, Offline } from "react-detect-offline";
import Product from './Product';

import { Context } from '../Context';

import { Detector } from "react-detect-offline";

import EditDialog from './base/EditDialog';
import EditPreprocessingDialog from './EditPreprocessingDialog';

import NetworkSettings from './NetworkSettings';

import browserhistory from '../Browserhistory';


const useStyles = makeStyles(theme => ({
  card: {
    minHeight: '200px',
  },
  cardProduct: {
    // minHeight: '200px',
  },
  networkContainer: {
    display: 'grid',
    gridTemplateColumns: '30% auto',
  },
  networkData: {
    gridColumnStart: 2,
  },
  networkDataname: {
    gridColumnStart: 1,
  },
  languageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  languageSelect: {
    marginLeft: theme.spacing(1.5),
  },
  center: {
    minHeight: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
  },
  break: {
    flexBasis: '100%',
  },
  pinIcon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actionLabel: {
    paddingRight: theme.spacing(1.5),
  },
}));

function Settings() {
  const classes = useStyles();
  const context = useContext(Context);

  const { t, i18n } = useTranslation();
  const [productRef, setProductRef] = React.useState(null);
  const [isProductCreating, setIsProductCreating] = React.useState(false);
  const [productDeleteDoc, setProductDeleteDoc] = React.useState(null);
  const [ioStatus, setIOStatus] = React.useState({});

  const [isCheckingUpdate, setIsCheckingUpdate] = React.useState(false); //TODO: figure out our initial state...

  const [isAccessCodeChecked, setIsAccessCodeChecked] = React.useState(false);
  const [isAccessCodeWrong, setIsAccessCodeWrong] = React.useState(false);

  const [editPreprocessing, setEditPreprocessing] = React.useState(false);

  const [isShowNetworkSettings, setIsShowNetworkSettings] = React.useState(false);


  let outputNames = {
    '1': t('accepted'),
    '2': t('rejected'),
    '3': t('malfunction'),
    '4': t('not used'),
  }

  useEffect(() => {
    console.log("getting ioStatus")
    context.socket.emit('getIOStatus', {}); // get it once..

    console.log('getting networkSettings');
    context.socket.emit('getNetworkSettings', {});
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing

  useEffect(() => {
    let listener = (payload) => {
      console.log('ioStatus');
      console.log(payload);
      setIOStatus(payload);
    };
    context.socket.on('ioStatus', listener);
    return () => {
      console.log('removing ioStatus listener - useEffect CLEANUP');
      context.socket.off('ioStatus', listener);
    }
  // eslint-disable-next-line
  }, []); // we rely upon the socket not changing

  //
  // function handleNetworkEnabledChange() {
  //   context.socket.emit('setNetworkSettings', {'isEnabled': !context.networkSettings.isEnabled}); // already setting the new state..
  // }

  let isManualTrigger = context.deviceSettings.data().isManualTrigger ;

  function handleManualTriggerChange() {
    context.setDeviceSettings({isManualTrigger: !isManualTrigger});
  }

  function handleProductOpen(doc) {
    if(doc === null) { // lets create a new product..
      setIsProductCreating(true);
      let data = {
        name: t('<product name>'),
        exposure: 5, // default exposure
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('products').add(data)
        .then((addRef) => {
          setIsProductCreating(false);
          console.log('new product...');
          console.log(addRef);
          setProductRef(addRef);
        });
    } else {
      setProductRef(doc);
    }
  }

  function handleProductClose() {
    setProductRef(null);
  }

  function handleProductDelete(doc) {
    setProductDeleteDoc(doc);
  }

  function handleProductDeleteDialogCancel() {
    setProductDeleteDoc(null);
  }

  function handleProductDeleteDialogConfirm() {
    setProductRef(null);
    productDeleteDoc.ref.delete().then(function() {
      console.log("Product successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
    setProductDeleteDoc(null);
  }

  const [productsValue, productsLoading, productsError] = useCollection(
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId).collection('products').orderBy('name').orderBy('_createdAt', 'asc')
  );
  if (productsError) console.error(productsError);


  function findProduct(){
    if (!_.isObject (productsValue)) return undefined;
    let tmp = _.find(productsValue.docs, (doc) => { return doc.ref.path === productRef.path})
    return tmp;
  }

  function handleSetRisingEdge(type, key, value) {
    let tmpkey = 'ioStatus.'+type+"."+key+".isRisingEdge";
    let tmp = {}
    tmp[tmpkey] = value;
    context.setDeviceSettings(tmp);
  }

  function handleSetIsIoTesting(value) {
    let isEnabled = value ? true : false;
    let tmp = {
      'ioStatus.isTesting': isEnabled
    }
    context.setDeviceSettings(tmp);
  }

  function handleSetPinTesting(key, value) {
    let isEnabled = value ? true : false;

    let tmp = {};
    tmp['ioStatus.outputTestingHigh.'+key] = isEnabled;
    console.log('SET:' , tmp)
    context.setDeviceSettings(tmp);
  }

  function handleAccessCodeCancel () {
    setIsAccessCodeWrong(false);
    browserhistory.push('/inspection');
  }

  function handleAccessCodeCheck(accessCode) {
    console.log(accessCode)
    console.log(_.get(context.deviceSettings.data(), 'accessCode'))
    if (accessCode === _.get(context.deviceSettings.data(), 'accessCode')) {
      setIsAccessCodeChecked(true);
      setIsAccessCodeWrong(false);
    } else {
      setIsAccessCodeWrong(true);
    }
  }

  // output accessCode entry if required
  if (isAccessCodeChecked === false && _.get(context.deviceSettings.data(), 'accessCode')) {
    return (
      <EditDialog
        handleClose = {handleAccessCodeCancel}
        handleSave = {handleAccessCodeCheck}
        name={''}
        hint={isAccessCodeWrong ? t('Wrong Access Code') : null}
        saveText={t("Unlock")}
        labelText={t("Access Code")}
        showKeyboard={true}
       />
    );
  }


    const showNetworkSettings = () => {
      setIsShowNetworkSettings(true);
    }
    const hideNetworkSettings = () => {
      setIsShowNetworkSettings(false);
    }

  return  (
      <React.Fragment>
        { isShowNetworkSettings &&
          <NetworkSettings handleClose={hideNetworkSettings} socket={context.socket} networkSettingsInit={context.networkSettings}/>
        }
        { productDeleteDoc !== null &&
          <Dialog
            open={true}
            onClose={handleProductDeleteDialogCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('Delete Product?')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('You are about to delete the product. This will not delete Tasks. This action cannot be undone', {productname: productDeleteDoc.data().name})}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleProductDeleteDialogCancel} >
                {t('Cancel')}
              </Button>
              <Button onClick={handleProductDeleteDialogConfirm} color="secondary" autoFocus>
                {t('Delete Product')}
              </Button>
            </DialogActions>
          </Dialog>
        }
        {(productRef !== null && findProduct() !== undefined) &&
          // use the real obbject - otherwise updates  ay get lost.
          <Product productDoc={findProduct()} handleClose={handleProductClose} handleProductDelete={handleProductDelete}/>
        }

        { editPreprocessing === true &&
          <EditPreprocessingDialog handleDialogClose={() => setEditPreprocessing(false)} deviceDoc={context.deviceSettings} />
        }
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardHeader
                title={t("Network")}
              />
              <CardContent>
                <div className={classes.networkContainer}>
                  <div className={classes.networkDataname}>
                    {t('IP')}
                  </div>
                  <div className={classes.networkData}>
                    {_.get(context, 'networkSettings.ipv4.ip')}
                  </div>
                  <div className={classes.macnetworkDataname}>
                    {t('MAC')}
                  </div>
                  <div className={classes.networkData}>
                    {_.get(context, 'networkSettings.mac')}
                  </div>
                  <div className={classes.networkDataname}>
                    {t('Gateway')}
                  </div>
                  <div className={classes.networkData}>
                    {_.get(context, 'networkSettings.ipv4.gateway')}
                  </div>
                  <div className={classes.networkDataname}>
                    {t('DNS')}
                  </div>
                  <div className={classes.networkData}>
                    {_.get(context, 'networkSettings.dns.primary') && _.get(context, 'networkSettings.dns.primary')}
                    {_.get(context, 'networkSettings.dns.secondary') && ", " + _.get(context, 'networkSettings.dns.secondary')}
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <Button onClick={showNetworkSettings}>{t('edit')}</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardHeader title={t("Device")} />
              <CardContent>
                <div>
                  <div>
                  <FormControlLabel
                    style={{marginLeft: '0px'}}
                    labelPlacement="start"
                    control={
                      <Switch checked={isManualTrigger} color="primary" onChange={handleManualTriggerChange} value="isManualTrigger" />
                    }
                    label={t('Manual Trigger')}
                  />
                  </div>
                  <div className={classes.languageContainer}>
                    <Typography>
                    {t('Language')}
                    </Typography>
                    <Select
                      className={classes.languageSelect}
                      value={i18n.language}
                      onChange={(event) => {
                          console.log(event.target.value);
                          i18n.changeLanguage(event.target.value, () => {
                        });
                      }}
                      inputProps={{
                        name: 'age',
                        id: 'age-simple',
                      }}
                    >
                      <MenuItem value={'de'}>Deutsch</MenuItem>
                      <MenuItem value={'en'}>English</MenuItem>
                    </Select>
                  </div>
                </div>
              </CardContent>
              <CardActions>
              <Button onClick={() => firebase.auth().signOut()}>
                {t('Logout')}
              </Button>
              { context.deviceSettings.data().preprocessing &&
                <Button onClick={() => setEditPreprocessing(true)}>
                  {t('Preprocessing')}
                </Button>
              }
              { context.isUpdateAvailable ?
                  <Button onClick={() => {
                    try {
                      context.isUpdateAvailable.waiting.postMessage('skipWaiting')
                    } catch (error)  {}
                  }}>{t('Update')}</Button>
                  :
                  <Detector
                    render={( {online} ) => {
                      return <Button disabled={isCheckingUpdate || !online} onClick={() => {
                          console.log('checking for update.. .');
                          setIsCheckingUpdate(true);
                          let tmp = context.checkUpdate()
                          if (tmp) {
                            console.log('THEN REACHED..');
                            tmp.then((registration) => {
                              console.log('after then....');
                              console.log(registration);
                              // if (registration.waiting) {
                              //   console.log('UPDATE AVAILABLE...');
                              //   context.setIsUpdateAvailable(registration);
                              // }
                              setIsCheckingUpdate(false);
                            })
                            .catch((err) => {
                              console.log('Error updating service worker..');
                              console.error(err);
                            });
                          }
                        }}>
                        {t('check for updates')}
                        {
                          isCheckingUpdate &&
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        }
                      </Button>
                    }} />

              }
              </CardActions>
            </Card>
          </Grid>

        { context.networkSettings.isEnabled && //<Online>
          <React.Fragment>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardHeader
                  title={t("I/O Configuration")}
                  action={
                    <FormControlLabel
                        className={classes.actionLabel}
                        value="start"
                        control={
                          <Switch checked={_.get(context.deviceSettings.data(), 'ioStatus.isTesting')} color="primary" onChange={() => {handleSetIsIoTesting(!_.get(context.deviceSettings.data(), 'ioStatus.isTesting') ) }} value="networkEnabled" />
                        }
                        label={t('Test output pins')}
                        labelPlacement="start"
                      />
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                  {
                    _.map(ioStatus.inputs, (e, key) => {
                      return (
                        <Grid item xs={3} key={'input' + key}>
                        <Typography>{t('Pin')} {key} <Typography variant="caption">({t('input')})</Typography></Typography>
                          <div className={classes.pinIcon}>{e.isOn ? <PinHighIcon color="primary" /> : <PinLowIcon />}</div>
                          <RadioGroup value={_.get(context.deviceSettings.data(), 'ioStatus.inputs[' + key + '].isRisingEdge')} onChange={()=>{handleSetRisingEdge('inputs', key, _.get(context.deviceSettings.data(), 'ioStatus.inputs[' + key + '].isRisingEdge') ? false : true)}}>
                            <FormControlLabel value={true} control={<Radio color="primary" />} label={t('Raising Edge')} />
                            <FormControlLabel value={false} control={<Radio color="primary" />} label={t('Falling Edge')} />
                          </RadioGroup>
                        </Grid>
                      )
                    })
                  }
                  {
                    _.map(ioStatus.outputs, (e, key) => {
                      return (
                        <Grid item xs={3} key={'output' + key}>
                          <Typography>{t('Pin')} {key} <Typography variant="caption">({outputNames[key]})</Typography></Typography>
                          <div className={classes.pinIcon}>{e.isOn ? <PinHighIcon color="primary" /> : <PinLowIcon />}</div>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={_.get(context.deviceSettings.data(), 'ioStatus.isTesting') ? false : true}
                                checked={_.get(context.deviceSettings.data(), 'ioStatus.outputTestingHigh.' + key) ? true : false}
                                onChange={()=> {handleSetPinTesting(key,  !_.get(context.deviceSettings.data(), 'ioStatus.outputTestingHigh.' + key))}}
                                value="checkedB"
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      )
                    })
                  }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>





            <Grid item xs={3}>
              <Card className={classes.cardProduct}>
                    { isProductCreating &&
                      <CardContent className={classes.center} style={{position: 'relative'}}>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      </CardContent>
                    }
                    { !isProductCreating &&
                      <CardActionArea onClick={_.partial(handleProductOpen, null)}>
                        <CardContent className={classes.center}>
                          <div>
                            <AddIcon />
                          </div>
                          <div className={classes.break} />
                          <div>
                            <Typography variant="h6">{t('Add new Product')}</Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    }
              </Card>
            </Grid>
            { (!productsLoading && productsValue) &&
              _.map(productsValue.docs, (doc) => {
                return  <Grid item xs={3} key={doc.ref.path}>
                          <Card className={classes.cardProduct}>
                            <CardActionArea onClick={()=>handleProductOpen(doc.ref)}>
                            {_.has(doc.data(), 'image.imageUrl') &&
                              <CardMedia
                                component="img"
                                alt="Product Image"
                                height="200"
                                image={doc.data().image.imageUrl}
                                title="Contemplative Reptile"
                              />

                            }
                              <CardHeader
                              avatar={
                                  _.some(doc.data().regions, (region) => { // EdgeMode is off if any associated task has not its aiSettings.isEdgeMode set to true (implying as well if the aiSettings or isEdgeMode are missing)
                                    return _.some(region.tasks, (taskPath, taskId) => {
                                      return _.find(context.taskDocs.docs, (o) => {
                                        return (o.id===taskId && _.get(o.data(), 'aiSettings.isEdgeMode') !== true)
                                      })
                                    })

                                    // _.get(doc.data, 'aiSettings.isEdgeMode')
                                  }) ?
                                  <AiCloudIcon /> : <AiEdgeIcon />
                                }
                                title={doc.data().name}
                                />
                            </CardActionArea>
                          </Card>
                        </Grid>
              })
            }
            </React.Fragment>
          }
          </Grid>

          {!context.networkSettings.isEnabled && //<Offline>
            <Typography variant="h6" color="secondary">{t('Go Online to access settings')}</Typography>
          }
          </React.Fragment>


    );


}

export default Settings;
